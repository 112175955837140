@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.connection-control.content-panel {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .body-content {
    flex-grow: 1; }

  .title-bar {
    display: flex;
    flex-direction: row;
    padding-bottom: 1.5rem;

    .logo {
      height: 44px;
      margin-right: 1.5rem; } }

  // connect/disconnect buttons
  .connect-disconnect-button-wrapper {
    padding-top: 1.5rem;
    .button {
      border-radius: 25px;
      font-weight: 500;
      padding-left: 15px;

      .button-content {
        @include center-content(); }
      .logo {
        height: 24px;
        width: 24px;
        margin-right: 10px; }
      &.disconnect {
        .button-content {
          color: $white; }
        .icon {
          background: $white;
          height: 20px;
          width: 20px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center; } } } }

  // other buttons (import items)
  .connection-button .button {
    margin: 1rem 0; }

  // alerts
  .connection-control-alert {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0 1rem;
    &, a {
      color: $red; }
    p {
      margin-top: 0; }
    svg {
      flex-shrink: 0;
      margin-right: 1rem;
      margin-top: 2px; } }

  // 3rd party specifics
  &.xero .disconnect {
    background: $blue-xero;
    svg {
      color: $blue-xero; } }
  &.quickbooks .disconnect {
    background: $green-quickbooks;
    svg {
      color: $green-quickbooks; } }
  &.zapier .disconnect {
    background: $red-zapier;
    svg {
      color: $red-zapier; } }
  &.stripe .disconnect {
    background: $purple-stripe;
    svg {
      color: $purple-stripe; } } }

// .taxes-table {
//   display: inline-block
//   vertical-align: top
//   margin-right: 20px
//   border: 1px solid #c4c4c4
// }

// .taxes-table th {
//   border-bottom: 1px solid #c4c4c4
// }

// .taxes-table tr:last-child td {
//   border-bottom: 0px solid #c4c4c4
// }

// .taxes-table tbody tr:hover,
// .taxes-table tbody tr.selected {
//   box-shadow: none
// }

// .taxes-table .percentage-cell {
//   width: 40px
//   text-align: right
//   padding-right: 3px
// }



@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.refer-a-friend-body {
  @include container-background(2rem);
  text-align: center;
  .referral-code {
    font-size: 1.3rem;
    font-weight: 600;
    display: block;
    padding: 1rem 0;
    .referral-token-copy-icon {
      margin-left: 1rem;
      cursor: pointer; } }
  .refer-a-friend-copy-container {
    @include container-background(2rem);
    background: $white;
    p {
      margin: 0; }
    span {
      display: block; }
    .referral-link {
      font-weight: bold;
      margin-top: 2rem; } }

  .copy-message-button {
    margin: 2rem auto 0;
    min-width: 230px; } }

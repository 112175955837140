
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.deal-listed-jobs-table {

    table {
        thead {
            th {
                padding-left: 1rem;
                padding-right: 1rem; } }
        tbody {
            tr {
                td {
                    padding: 1em 1em;

                    &.customer-contact-table-cell, &.reference-table-cell {
                        border-right: 1px solid rgba($blue-dark, 0.2); }

                    &.reference-table-cell {
                        width: 100px; }

                    &.value-table-cell, &.amount-due-table-cell {
                        width: 125px; }

                    &.status-table-cell {
                        width: 125px; }

                    &.updated-at-table-cell {
                        width: 125px; }

                    &.customer-contact-table-cell {
                        > * {
                            display: block; } } } } } } }

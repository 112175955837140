
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

$control-width: 200px;

.header-navigation-control {

    .sm-view {
        .show-popper-trigger {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include center-content();
            transition: box-shadow 0.1s ease-in-out; } }


    .sm-view.active {
        .show-popper-trigger {
            @include box-shadow(); } }


    &:not(.sm-view) {
        width: $control-width; }

    .show-popper-trigger {

        & > svg {
            width: 22px;
            height: 22px; }


        button {
            width: 100%;
            height: 40px;
            border-radius: 20px;
            pointer-events: none;

            .button-content {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 400;
                line-height: 1.5em; } } } }

.header-navigation-control-popper {
    width: $control-width; }

.header-navigation-control-drawer {

    .MuiPaper-root {

        .header-navigation {
            padding: 0.5rem 0; }

        .account-user-indicator {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            background-color: $blue-lighter;

            .account-name {
                font-weight: 600; } } } }


@media (min-width: 400px) {

    .responsive-popper-and-trigger-drawer {

        .MuiPaper-root {
            .account-user-indicator {
                flex-direction: row;

                .account-name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex-grow: 1; }

                .user-name {
                    white-space: nowrap; } } } } }

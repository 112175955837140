
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.customer-contacts-control {
  .add-contact-trigger-container {
    width: 100%;
    button {
      min-width: 300px;
      margin: 0 auto;
      justify-content: center;

      .button-content {
        flex-grow: 0; } } }

  .customer-contact-control-container {
    flex-basis: calc(50% - 2rem);
    border: 1px solid $yellow !important;
    position: relative;

    .delete-contact-trigger {
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      color: $red;
      cursor: pointer;

      small {
        display: flex;
        align-items: center;

        .fa-times {
          margin-left: 0.25rem; } } } }

  .customer-contact-controls-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2rem;
    margin-right: -2rem;

    > * {
      margin-right: 2rem;
      margin-bottom: 2rem; } }

  &.sm-view {
    .add-button, .customer-contact-control-container {
      flex-basis: calc(100% - 2rem); } }

  &.xs-view {

    .add-button, .customer-contact-control-container {
      flex-basis: calc(100% - 2rem); }

    .add-button {
      min-height: 4rem; } } }

.customer-contact-control-container .title .fa-times {
  cursor: pointer;
  color: rgba($blue-dark, 0.5); }

.company-contacts-form-subsection .add-button {
  width: 100%;
  text-align: center;
  border: 1px dashed #c4c4c4;
  padding: 0.5rem;
  transition: background-color 300ms;
  cursor: pointer;
}

.company-contacts-form-subsection .add-button .fa-plus {
  margin-right: 0.5rem;
}

.company-contacts-form-subsection .add-button:hover {
  background-color: #f3f3f1;
}

.company-contacts-form-subsection > *:not(:last-child) {
  margin-bottom: 1rem;
}

.supporting-documents-form-section h3 {
  font-size: 1.1rem;
}

.supporting-documents-form-section
  form
  .field-group.director-id-field-group
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.supporting-documents-form-section
  form
  .field-group.director-address-field-group
  .proof-of-address {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.supporting-documents-form-section
  .director-id-field-container
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.supporting-documents-form-section .field-label {
  flex-direction: column;
  align-items: flex-start;
}

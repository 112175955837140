
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.deal-customer-control {
    width: 360px;

    .deal-customer {
        position: relative;
        background-color: $blue-lighter;
        @include border-radius();
        padding: 1rem;
        width: 100%;
        .actions-container {
            position: absolute;
            top: 1rem;
            right: 1rem;

            svg {
                cursor: pointer; } } } }

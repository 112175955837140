@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.list-view-page-wrapper {
    .body-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: hidden; }

    .pagination-control-container {
        padding: 1rem 2rem 2rem 4rem; }

    .list-view-content {
        overflow-y: scroll;
        flex-grow: 1; } }




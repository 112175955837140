@import '@payaca/styles/variables.sass';

@keyframes spin {
  from {
    transform: rotate(0deg); }

  to {
    transform: rotate(360deg); } }

.loqate-advisory-phone-input-field {
  .fa-times {
    color: $red; }

  .fa-check {
    color: $green-dark; }

  .fa-circle-notch {
    color: $blue-light;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.preview-job-control {
    .lhs {
        background-color: $blue-lighter;
        @include border-radius();

        .title-bar {
            padding: 2rem; }

        .preview-content {
            padding: 2rem;
            padding-top: 1rem;

            .content-panel {
                border-radius: 0; } } }

    .sidebar-content {

        > *:not(:last-child) {
            margin-bottom: 2rem; }

        .marked-as-sent-explainer-microcopy {
            display: block;
            text-align: center; } } }

#alert-dialog-title {
  font-family: Poppins;
  font-weight: 600;
  padding: 0px;
}

.MuiDialogContent-root.content {
  padding: 8px 24px 24px;
}

.MuiDialogContent-root.content .list-item,
.MuiDialogContent-root.content .list-item label {
  cursor: pointer;
}

.MuiDialogContent-root.content .list-item:hover {
  background: #fab62d;
}

.MuiDialogContent-root.content .button-container {
  display: flex;
  justify-content: space-around;
}

.modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.modal-header-wrapper .buttons-container > *,
.modal-header-wrapper .close-icon {
  cursor: pointer;
}

/** ================= Confirm modal ================= */
.confirm-modal.title h2 {
  font-size: 20px;
  text-align: center;
}

.confirm-modal.content .button-container {
  margin-top: 2rem;
  justify-content: center;
}

.confirm-modal.content .button-container > *:not(:last-child) {
  margin-right: 1rem;
}

.no-email-customer.confirm-modal.content .button-container .button {
  width: 200px;
}

.email-verification .button-container {
  flex-direction: column;
  align-items: center;
}

.email-verification .button-container .button.tertiary {
  margin-bottom: 0;
}


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-timeline-events {
  position: relative;

  .job-timeline-event {
    &:not(:last-child) {
      margin-bottom: 0.75rem; } }

  .job-timeline-events-container {
    position: relative;
    z-index: 10; }

  .timeline {
    width: 1px;
    top: 0;
    bottom: 0;
    position: absolute;
    border-left: 1px solid rgba($blue-dark, 0.5);
    left: 50%;
    z-index: 0; } }

.modal.update-subscription-modal {
  .subscription-update-success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    padding: 2rem 0;

    .fa-check {
      font-size: 2rem;
      color: rgb(139, 195, 74); } }

  .actions-container {
    flex-direction: row;
    padding-top: 1.5rem;
    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 3rem; } } }

.form-section .form-section-title-wrapper {
  border-bottom: 1px solid #e6e6e6;
  background: #fff2cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-section .form-section-title {
  padding: 10px;
  margin: 0px;
  color: #263e59;
}

.form-section .form-section-content {
  margin: 20px;
}

.user-invite-form-container {
    min-height: 220px;

    form {
        .password-validation-feedback {
            padding-top: 0.5rem; }

        > *:not(:last-child) {
            margin-bottom: 1rem; }

        button.button-style-outsize {
            width: 100%;

            & + .feedback-level-error {
                margin-top: 0.5rem; } } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';


.manage-subscription-page-content {
  > *:not(:last-child) {
    margin-bottom: 2rem; }

  .view-comparison-chart-trigger-container {
    width: 100%;
    @include center-content(); } }



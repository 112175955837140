.subsidised-finance-information-form-progress-tracker {
  max-width: 750px;
  margin: 0 auto;
}

.subsidised-finance-information-form-progress-tracker
  :not(.completed-step)
  > .step-inner
  > .step-indicator.clickable {
  cursor: default;
  pointer-events: none;
}

@media (max-width: 750px) {
  .subsidised-finance-information-form-progress-tracker {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .subsidised-finance-information-form-progress-tracker {
    width: 300px;
    margin: 0;
  }
}

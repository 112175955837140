.edit-job-control {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .body-with-sidebar-content-wrapper {
    .title-bar {
      .edit-job-save-feedback {
        justify-content: flex-end;
        flex-grow: 1; } }

    .sidebar {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: 0;

      .scrollable-content-container {
        flex-grow: 1;
        overflow-y: scroll;
        padding: 1rem; }

      .static-content-container {
        padding: 1rem;

        button {
          width: 100%; } } }

    .body-content {
      margin: 0 -2rem;
      padding: 2rem 2rem;

      > *:not(:last-child) {
        margin-bottom: 2rem; }

      > .content-panel {
        > h4 {
          margin: 0; } } } }

  .create-or-add-existing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;


    .or {
      font-size: 0.9rem; }

    > *:not(:last-child) {
      margin-right: 1rem; }

    .clickable-area {
      flex-basis: 300px;
      justify-content: center;
      padding-top: 1rem;
      padding-bottom: 1rem; } }

  .titled-content-panel {
    > .title {
      .checkbox-field {

        flex-direction: row-reverse;

        > * {
          margin-right: 0.5rem; } } } }

  &.sm-view {
    .body-with-sidebar-content-wrapper {
      display: block;
      overflow-y: visible;

      > *:not(:last-child) {
        margin-right: 0; }

      .sidebar {
        width: 100%; }

      .main {
        height: auto; } } } }



.personal-settings .page-content {
  justify-content: flex-start;
  align-items: center;
}
.personal-settings .show-password-button-container {
  margin-top: 1rem;
}

.personal-settings .show-password-button-container .button {
  width: 250px;
}

.create-edit-line-item-group-control {
  height: 100%;
  flex-grow: 1;

  &.sm-view {
    height: auto;

    .body-with-sidebar-content-wrapper {
      flex-direction: column;

      > *:not(:last-child) {
          margin-right: 0rem;
          margin-bottom: 2rem; }

      .sidebar {
        width: 100%; } } }

  .body-with-sidebar-content-wrapper {

    .title-bar {
      border: none; }


    .main {
      flex-grow: 1;
      flex-shrink: 1; }

    .sidebar {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      height: auto;
      align-self: flex-start;
      min-width: 385px; }

    .sidebar-content {
      > * {
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 1rem; } }

      .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        > *:not(:last-child) {
          margin-bottom: 1rem; }

        button.button-style-outsize {
          width: 100%; } } }

    .body-content {
      margin: -1rem -2rem -1rem -2rem;
      padding: 1rem 2rem 1rem 2rem;
      display: flex;

      .content-panel {
        flex-grow: 1;
        overflow: hidden;
        padding: 0; }

      form {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;

        .scrollable-content {
          padding: 1rem 1rem 0 1rem;
          overflow-y: scroll;
          flex-grow: 1;

          >*:not(:last-child) {
            margin-bottom: 1rem; } }

        .field-label {
          flex-direction: column;
          align-items: flex-start; }

        .add-item-button-container {
          padding: 1rem;
          .button {
            margin: 0 auto;
            width: 100%;
            max-width: 400px;
            justify-content: center;

            .button-content {
              flex-grow: 0; } } } } } }

  .line-item-controls-container {
    > *:not(:last-child) {
      margin-bottom: 1rem; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.listed-line-item-group-tile {
    min-width: 250px;
    height: 230px;
    display: flex;
    flex-direction: column;

    .title-container {
        h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } }

    .body-container {
        flex-grow: 1;
        overflow-y: scroll;

        .description {
            margin: 0; }

        .description, .line-item-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; } } }



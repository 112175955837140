.new-edit-job-header {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
}

.new-edit-job-header .progress-bar {
  flex-grow: 1;
}

.new-edit-job-header .buttons-container {
  display: flex;
}

.new-edit-job-header .buttons-container .button {
  margin-right: 1rem;
  min-width: 135px;
}

.new-edit-job-header .processing-container {
  flex-shrink: 1;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.new-edit-job-header .error-message,
.new-edit-job-header .saved-message {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.new-edit-job-header .error-message.hidden,
.new-edit-job-header .saved-message.hidden {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.new-edit-job-header .messages-container {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.new-edit-job-header .error-message .fa-exclamation-triangle {
  margin-right: 0.5rem;
}

@media (max-width: 600px) {
  /* hide progress bar on mobile */
  .new-edit-job-header .progress-bar {
    display: none;
  }
  .new-edit-job-header {
    flex-direction: row-reverse;
  }
  .new-edit-job-header .buttons-container .button {
    margin-right: 0.5rem;
  }
}

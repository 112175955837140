@import '../../styles/variables.sass';
@import '../../styles/mixins.sass';

.tile {
    @include border-radius();
    background-color: $blue-lighter;
    padding: 0 1rem;
    position: relative;
    border: 1px solid $blue-lighter;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    &.box-shadow {
        @include box-shadow(); }

    .title-container {
        padding-top: 1rem;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid rgba($blue-darker, 0.2);
        transition: border-color 0.3s ease-in-out;

        h3 {
            margin: 0; } }

    .body-container {
        padding: 1rem 0; }

    .footer-container {
        padding-bottom: 1rem;
        padding-top: 0.75rem;
        border-top: 1px solid rgba($blue-darker, 0.2);
        transition: border-color 0.3s ease-in-out; }

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: $white;
            border-color: $blue-light;

            .title-container, .footer-container {
                border-vcolour: $blue-light; } } } }

.setup-account-branding-form {
  width: 100%;
  .file-upload-persist-remove-control, .validated-field-wrapper {
    margin-bottom: 1rem;
    .persisted-files {
      padding: 0;
      margin: 0.5rem 0 2rem; } }

  .upload-status {
    text-align: center; }

  .button-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.5rem;

    button.button-style-outsize {
      width: 100%; }
    button.button-style-anchor {
      margin-top: 1rem; } } }


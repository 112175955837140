.resources-page-content {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.resources-page-content .content-panel {
  margin: 0.5rem;
  width: 100%;
}

.resources-page-content .no-active-subscription-content-panel {
  max-width: 600px;
  margin: 0.5rem auto;
  padding: 3rem;
  text-align: center;
}

.resources-page-content .updates-button-container {
  margin: 0.5rem;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

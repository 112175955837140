@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.contact-us-callout {
  background-color: $blue-lighter;
  max-width: 370px;
  padding: 1.5rem;
  text-align: center;
  @include border-radius();

  .contact-us-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      display: block;

      &:not(:last-child) {
        margin-bottom: 1rem; } }
    a, a:hover {
      text-decoration: none; }

    button.button-style-outsize {
      min-width: 240px; } }

  .contact-us-prompt {
    text-align: center; }

  h2 {
    margin: 0;
    line-height: 1.2em;
    text-align: center; }

  > *:not(:last-child) {
    margin-bottom: 1.5rem; } }

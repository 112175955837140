.initial-register-form-container {

    form {
        > *:not(:last-child) {
            margin-bottom: 1rem; }

        > button {
            width: 100%; } }

    .collapsible-panel {
        margin-left: -1rem;
        margin-right: -1rem;

        .collapsible-panel-body {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem; }

        &.open {
            margin-bottom: -1rem;
            .collapsible-panel-header {
                cursor: default; } }


        .collapsible-panel-header {
            padding-left: 1rem;
            padding-right: 1rem;

            button {
                display: inline; }

            .control-icon-container {
                display: none; }

            .enter-referral-code-prompt {
                text-align: center; } } } }

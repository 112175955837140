.job-overview-notes {
  white-space: pre-wrap;
  margin-bottom: 1em;
}

.job-overview-notes p {
  margin: 0;
  line-height: 1.4;
}

.job-overview-notes p:first-child {
  margin-bottom: 0.25em;
}

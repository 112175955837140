.list-item {
  display: flex;
  color: #222;
  padding: 20px;
  align-items: center;
}

/* ================ BasicListItem ================ */
.list-item .edit-icon {
  height: 30px;
  width: auto;
}
.customers .list-item,
.items .list-item {
  justify-content: space-between;
}

/* ================ SettingListItem ================ */
.main-help.list-item .img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.main-help.list-item .list-item-title {
  font-weight: bold;
}

.list-item-content-wrapper {
  display: flex;
}

.main-help.list-item .list-item-title {
  font-weight: bold;
}

.list-item.arrowed {
  justify-content: space-between;
}

.list-item a .list-item-content-wrapper {
  /* not stretching container !!!!!!! */
  flex-grow: 1;
  width: 100%;
}

.list-item-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.member-benefits.list-item .img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.list-item-title {
  display: block;
}

.list-item-description {
  color: #fab62c;
}

.list-item .arrow-icon {
  font-size: 24px;
}

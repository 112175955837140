.advanced-create-edit-customer-control {
  h4 {
    margin: 0;
    margin-bottom: 1rem; }

  form > *:not(:last-child) {
    margin-bottom: 2rem; }

  hr {
    margin-top: 0; } }

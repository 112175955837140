.status-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  max-width: 27rem;
  margin: auto;
  height: 100%;
}

.status-error img {
  height: 150px;
}

.status-error h4 {
  margin-top: 1rem;
}

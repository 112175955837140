@import '@payaca/styles/mixins';
@import '@payaca/styles/variables';

.taxes-table {
  table {
    width: 100%;
    border-collapse: collapse;
    thead th:not(.arrow-col) {
      min-width: 200px;
      text-align: left;
      padding: 1rem 0 0.5rem 0;
      &:last-child {
        padding-left: 1rem; } }
    tbody {
      td {
        background: $blue-light;
        padding: 0.5rem 1rem;
        cursor: default;
        &.arrow-cell {
          padding: 0; } }
      tr:first-child td:first-child {
        border-top-left-radius: 5px; }
      tr:first-child td:last-child {
        border-top-right-radius: 5px; }
      tr:last-child td:first-child {
        border-bottom-left-radius: 5px; }
      tr:last-child td:last-child {
        border-bottom-right-radius: 5px; }
      .percentage-cell {
        width: 40px;
        text-align: right; } } }

  .dropdown-field {
    .input-wrapper.input-wrapper-unstyled {
      background-color: $blue-light;
      padding: 1rem;
      @include border-radius-sm(); }
    .field-label, .input-wrapper div {
      font-weight: 700; } }

  .checkbox-field .field-label {
    font-size: 0.9rem; }

  .save-connection-button-container {
    .button {
      min-width: 290px;
      margin: 1rem auto 0; } } }

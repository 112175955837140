.mark-as-sent-modal {
  padding-top: 0;
}

.mark-as-sent-modal > * {
  margin: 0;
}

.mark-as-sent-modal > *:not(:last-child) {
  margin-bottom: 1rem;
}

.mark-as-sent-modal .button-container {
  padding-top: 1rem;
  justify-content: flex-end !important;
}

/* ======================= Walkthrough =======================*/
.walkthrough-wrapper {
  width: 100%;
  position: relative;
}

.walkthrough-wrapper .connecter {
  position: absolute;
  width: 5px;
  height: 52.5%;
  background: rgba(250, 182, 45, 0.5);
  top: 17.5%;
  left: 42.5%;
}

.walkthrough-step-wrapper {
  position: relative;
}

.walkthrough-step-wrapper .connecter-dashed {
  position: absolute;
  width: 15%;
  left: 45%;
  height: 100%;
}

/* ======================= Walkthrough step =======================*/
.walkthrough-step {
  display: flex;
  justify-content: flex-start;
}

.walkthrough-step .walkthrough-image {
  width: 15%;
  align-self: flex-start;
  z-index: 10;
}

.walkthrough-step.reverse .walkthrough-image {
  align-self: center;
}

.walkthrough-step-wrapper:first-child img {
  align-self: flex-end;
}

.walkthrough-step .walkthrough-text-wrapper {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
}

.walkthrough-step .walkthrough-text-wrapper .walkthrough-title {
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 5px;
}

.walkthrough-step.reverse {
  flex-direction: row-reverse;
}

.walkthrough-description-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 5px;
}

.walkthrough-step .check-icon {
  border-radius: 50%;
  background: #fab62d;
  padding: 5px;
  color: #fff;
  font-size: 22px;
  margin: 5px 15px 5px 0px;
}

@media (max-width: 800px) {
  .walkthrough-wrapper .connecter,
  .walkthrough-step-wrapper .connecter-dashed {
    display: none;
  }

  .walkthrough-step,
  .walkthrough-step.reverse {
    flex-direction: column;
  }

  .walkthrough-step .walkthrough-text-wrapper {
    width: 100%;
    padding-left: 0;
    align-self: center;
    max-width: 550px;
  }

  .walkthrough-step .walkthrough-image,
  .walkthrough-step-wrapper:first-child img {
    align-self: center;
  }

  .walkthrough-step-wrapper:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .walkthrough-step > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .walkthrough-step .walkthrough-title {
    margin-bottom: 0.5rem;
  }
}

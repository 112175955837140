.bulk-upload-modal .button,
.bulk-upload-modal label {
  min-width: 200px;
}

.bulk-upload-modal label {
  display: flex;
}

.bulk-upload-modal label .button {
  justify-content: center;
}

.bulk-upload-modal label .button .icon {
  margin-right: 0.8em;
}

.bulk-upload-modal .upload-instructions-wrapper {
  text-align: center;
}

.bulk-upload-modal .errors-wrapper,
.bulk-upload-modal .upload-instructions-wrapper .guidelines-wrapper {
  text-align: left;
  font-size: 0.95em;
  margin-top: 0.5em;
}

.bulk-upload-modal
  .upload-instructions-wrapper
  .guidelines-wrapper
  li
  span:first-child {
  font-weight: 600;
}

.bulk-upload-modal .upload-instructions-wrapper .upload-instruction {
  margin-bottom: 1em;
}

.bulk-upload-modal .download-button-wrapper,
.bulk-upload-modal .upload-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.3em;
}

.bulk-upload-modal .upload-button-wrapper {
  position: relative;
  width: 200px;
  margin: 0.5rem auto;
}

.bulk-upload-modal .upload-button-wrapper label {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.bulk-upload-modal .button.processing > .button-content,
.button.processing > .icon {
  visibility: hidden;
}

.single-item-label-wrapper {
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.single-item-label {
  border: 1px solid #fab62d;
  background: #fff1cd;
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.info-text {
  padding-bottom: 5px;
}

.select-value-modal .button-container {
  display: block !important;
  width: 100%;
  margin: 1rem 0;
}

.select-value-modal .button-container .button:not(:last-child) {
  margin-bottom: 1rem;
}

.select-value-modal .button {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.select-value-modal .error {
  color: red;
}

.select-value-modal .input-field-container {
  width: 200px;
  margin: auto;
}

.select-value-modal .form-contents > *:not(:last-child) {
  margin-bottom: 1rem;
}

@import '@payaca/styles/variables';
@import '@payaca/styles/mixins';
.subscription-payment-method-control {
  .card-element-container {
    background: $blue-light;
    padding: 1rem 1.5rem;
    @include border-radius();

    .input-wrapper.input-wrapper-unstyled {
      border-bottom: 1px solid rgba($blue-darker, 0.2); } }

  .error-message {
    color: $red;
    font-size: 0.8em;
    line-height: 1.5em;
    margin: 0; } }

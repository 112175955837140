@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.line-item-group-line-item-control {
    width: 100%;
    display: flex;




    > *:not(:last-child) {
        margin-right: 1rem; }

    .is-selected-container {
        width: 1.5rem;
        @include center-content();

        .checkbox .MuiCheckbox-root {
            padding: 0; }

        .radio-button .radio-button-select-button {
            margin: 0; } }

    .line-item-group-line-item-control-inner {
        background-color: $blue-lighter;
        @include border-radius();
        padding: 1rem;
        position: relative;
        flex-grow: 1;

        .actions-container {
            position: absolute;
            top: 1rem;
            right: 1rem;

            .delete-container {
                svg {
                    cursor: pointer;
                    opacity: 0.5; } } }

        .price-and-vat-container {
            min-width: 75px;
            text-align: right; } } }

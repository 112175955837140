@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.clickable-area {
    background-color: $blue-lighter;
    padding: 2rem;
    width: 100%;
    @include center-content();
    @include border-radius();
    border: 2px dashed $blue-darker;
    transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: pointer;
    position: relative;



    .clickable-area-content {
        @include center-content(); }

    &.processing {
        .clickable-area-content {
            visibility: hidden; }

        .spinner-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include center-content(); } }

    &:hover {
        background-color: $blue-light; }

    &.create-area {
        .create-icon-wrapper {
            margin-right: 0.75rem;

            .create-icon {
                height: 22px;
                width: 22px;
                border-radius: 50%;
                border: 1px solid;
                @include center-content(); } } }

    &.area-col-secondary-light {
        background-color: $blue-light;

        &:hover {
            background-color: darken($blue-light, 5%); } }



    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.3; } }

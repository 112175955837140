@import '@payaca/styles/variables';

.regulatory-requirements-form-section .field-group.fca-field-group > *:not(:last-child) {
  margin-bottom: 1rem; }

.regulatory-requirements-form-section .field-group.ico-field-group > *:not(:last-child) {
  margin-bottom: 1rem; }

.regulatory-requirements-form-section p {
  font-size: 1rem; }

.regulatory-requirements-form-section p a {
  text-decoration: underline; }

.regulatory-requirements-form-section .field-label {
  display: block; }

.regulatory-requirements-form-section .field-label > * {
  display: block; }
.regulatory-requirements-form-section .radio-group-field .field-label > * {
  display: inline-block; }
.regulatory-requirements-form-section .radio-group-field .field-label .description {
  text-decoration: underline; }

.regulatory-requirements-form-section {
  .field-group .content-panel {
    background: $blue-lighter; } }

/* SMALL VIEW STYLES */

.regulatory-requirements-form-section.sm-view .fca-complaints-contact-form-subsection .content > .flex-container {
  flex-direction: column; }

.regulatory-requirements-form-section.sm-view .fca-complaints-contact-form-subsection .content > .flex-container > *:not(:last-child) {
  margin-bottom: 0.5rem; }

/* END SMALL VIEW STYLES */

.aggregate-value-bar-chart-container {
  padding-top: 65%;
  position: relative;
}

.aggregate-value-bar-chart-container svg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.aggregate-value-bar-chart-container svg > * {
  shape-rendering: crispEdges;
}

.aggregate-value-bar-chart-container svg .axis-label {
  font-size: 12px;
}

.aggregate-value-bar-chart-container .chart-tooltip {
  position: absolute;
  border-radius: 0.35rem;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  max-width: 300px;
  z-index: 1000;
  pointer-events: none;
  transition: opacity 0.1s;
  opacity: 0;
}

.aggregate-value-bar-chart-container .chart-tooltip p {
  margin: 0;
  font-size: 0.8rem;
}

.aggregate-value-bar-chart-container text.bar-label {
  font-size: 12px;
  transform: translate(0, 2px);
  alignment-baseline: central;
  z-index: 1000;
  pointer-events: none;
}

.aggregate-value-bar-chart-container text.internal-bar-label {
  text-anchor: end;
}

.aggregate-value-bar-chart-container text.no-display-bar-label {
  visibility: hidden;
}

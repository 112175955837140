.connections-page .page-content .body-content .connections-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .content-panel {
    margin: 1rem;
    width: 45%;
    justify-content: space-between; } }

@media (max-width: 800px) {
  .connections-page .page-content .body-content .connections-wrapper .content-panel {
    margin: 1rem 2rem;
    width: 100%; } }


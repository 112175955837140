
.setup-user-page {

  .body-content {
    margin: 0 auto;
    width: 100%;

    > *:not(:last-child) {
      margin-bottom: 2rem; } }

  .setup-user-form-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto; } }

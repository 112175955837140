.edit-job-save-feedback {

    .saved-message-container {
        white-space: nowrap; }

    .error-message-container, .saved-message-container {
        font-size: 0.9rem;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &.hidden {
            opacity: 0;
            width: 0;
            overflow: hidden; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.navigation-menu-item {
    display: flex;
    align-items: center;
    transition: background-color 0.1s ease-in-out;


    .title-container {
        margin-left: 1rem;
        transition: opacity 0.3s ease-in-out;
        flex-grow: 1;
        position: relative; }


    .icon-container {
        width: 36px;
        height: 36px;
        @include center-content();
        border-radius: 50%;
        transition: background-color 0.1s ease-in-out;

        .icon-container-inner {
            width: 28px;
            height: 28px;
            @include center-content(); } }

    &.disabled {
        .icon-container, .title-container > span {
            opacity: 0.5; } }

    &:not(.disabled) {
        cursor: pointer; }

    &.selected {
        background-color: rgba($white, 0.1);
        font-weight: 600; }
    &:hover {
        .tooltip-container {
            opacity: 1;
            transition: all ease-out 0.3s; } }

    .tooltip-container {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        top: -6px;
        left: 75px;
        font-size: 0.9rem;
        opacity: 0;
        transition: all ease-out 0.3s;
        .tooltip-text {
            color: $blue-darker;
            background: $col-primary;
            @include box-shadow;
            padding: 0.5rem;
            @include border-radius-sm();
            &::after {
                content: " ";
                position: absolute;
                top: 50%;
                left: 0%;
                margin-left: -12px;
                margin-top: -6.5px;
                border-width: 6px;
                border-style: solid;
                border-color: transparent $col-primary transparent transparent; } } } }

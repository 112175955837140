@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.listed-items-table {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;

    .pagination-control {
      padding: 1rem 0rem 1rem 3rem; }

    .no-results-container, .loader-container {
      @include center-content(); }

    .styled-table-wrapper {
        border-radius: 0;
        border: none;
        flex-grow: 1; }


    table.styled-table {



        td, th {
            padding: 0.5em 0.75em;
            vertical-align: middle; }


        th {
            white-space: nowrap;
            padding-top: 0;

            .row-selection-table-cell {
                width: 3rem;
                max-width: 3rem;
                max-height: 3rem; } }

        .synced-with-integrations-table-cell {}
        .synced-with-integrations-container {
            @include center-content();
            > * {
              max-height: 20px;
              width: 20px;

              &:not(:last-child) {
                margin-right: 0.25rem; } } }

        .description-table-cell {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 400px; }

        .price-including-vat-table-cell {
            .price {
                display: block;
                margin: 0;
                line-height: 1.2em; }

            .vat {
                display: block;
                margin: 0;
                line-height: 1.2em;
                opacity: 0.8;
                font-size: 0.8; } }

        .supplier-price-table-cell {
            .price {
                display: block;
                margin: 0;
                line-height: 1.2em; }

            .name {
                display: block;
                margin: 0;
                line-height: 1.2em;
                opacity: 0.8;
                font-size: 0.8; } }

        .cis-table-cell {
            .fa-check {
                color: $green-dark;
                display: block;
                margin: 0 auto; }

            .cis-deduction-rate {
                line-height: 0.5em;
                margin: 0;
                font-size: 0.8rem;
                white-space: pre; } } } }

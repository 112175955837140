@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-overview {
  padding-bottom: 1rem; }

/* JOB OVERVIEW TOTALS AND CUSTOMER NOTES */
.job-overview-totals-and-customer-notes-wrapper {
  display: flex;
  padding-top: 1.8rem;
  justify-content: flex-end;

  .job-overview-notes {
    @include container-background(); }

  .job-overview-totals {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 400px;
    @include container-background();
    margin-bottom: 1rem;
    .reverse-charge-instructions, .totals-container {
      width: 100%; }

    .label-value-pair {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;
      text-align: right;

      .value {
        min-width: 120px; }

      .label {
        margin-right: 0; } }

    .separator {
      @include border-top(2px);
      @include border-bottom(2px);
      margin: 0.5em 0;

      .label-value-pair {
        padding: 1rem 0; } }

    .reverse-charge-instructions {
      font-size: 0.8em;
      color: #888;
      margin-bottom: 1rem; }

    .job-overview-paid-stamp {
      position: absolute;
      right: 2.5rem;
      opacity: 0.8;
      img {
        max-height: 130px;
        max-width: 200px; } } } }

.job-overview.small-view {
  .cta-container {
    .standard-and-finance-panels-wrapper {
      max-width: 100%;
      .collapsible-panel .collapsible-panel-header {
        min-height: 42px;
        border-radius: 21px; } }
    .satisfaction-note-signature-capture-control {
      max-width: 100%; }
    .job-overview-cta {
      width: 100%;
      max-width: 100%;
      .button {
        width: 100%;
        min-height: 42px;
        border-radius: 21px; } } }
  .job-signature-capture-control {
    width: 100%;
    max-width: 100%; }
  .job-overview-totals-and-customer-notes-wrapper {
    flex-wrap: wrap;
    padding-top: 1rem;
    .job-overview-totals {
      width: 100%; } } }
/* END JOB OVERVIEW TOTALS ADN CUSTOMER NOTES*/

/* JOB OVERVIEW ITEMS SECTION */
.job-overview-item-group-section {
  position: relative;
  padding: 1.5rem;
  @include border-radius();
  border: 1px solid $blue-lighter;

  &.proposition {
    margin-top: 1em; }

  .job-overview-items-group-description {
    padding: 0;
    margin: 0 0 1rem 0; }

  .job-overview-items-wrapper {

    .job-overview-item-group-section-helper-title {
      position: absolute;
      margin-top: -0.75em;
      background-color: $white;
      font-size: 0.9em;
      font-style: italic;
      padding-right: 0.5rem; }

    .job-overview-items-section {
      div:not(:nth-of-type(0)) + .job-overview-item {
        margin-top: 0.7rem; }
      &.with-title {
        margin-top: 2.5rem;

        .job-overview-item-group-section-helper-border {
          @include border-bottom();
          margin-bottom: 0.5rem; }
        div:first-of-type + .job-overview-item {
          margin-top: 1.2rem; } } } } }

.job-overview.small-view {
  .job-overview-item-group-section {
    padding: 0.5rem;
    .job-overview-items-wrapper .job-overview-items-section .job-overview-item-group-section-helper-border {
      @include border-bottom(2px);
      border-color: $col-secondary; } } }

/* END JOB OVERVIEW ITEMS SECTION */

/* JOB OVERVIEW ITEM */
.job-overview-item {
  @include container-background(1.5rem);
  background: $blue-light;

  // unselected items
  &.unselected {
    background: $blue-lighter;
    .description-details-wrapper {
      color: rgba($col-secondary-dark, 0.5); } }

  .job-overview-item-inner {
    position: relative;
    &:hover {
      .tooltip-container {
        opacity: 1;
        transition: all ease-out 0.3s;
        top: -60px; } } }


  .tooltip-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    top: -20px;
    transition: all ease-out 0.3s;
    .tooltip-text {
      color: $blue-darker;
      background: $white;
      @include box-shadow;
      padding: 0.5rem 1.5rem;
      @include border-radius-sm();
      &::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: $white transparent transparent transparent; } } }

  & > * {
    display: flex;
    flex-direction: row; }

  &:hover:not(.disabled) {
    cursor: pointer;
    background: $blue-light;
    transition: background 300ms ease-out;
    .description-details-wrapper {
      transition: color 300ms ease-out;
      color: $col-secondary-dark; } }

  .radio-checkbox-description-container {
    flex-grow: 1;
    white-space: pre-wrap;
    word-wrap: break-word;
    display: flex;
    align-items: flex-start;
    .cost-difference {
      font-weight: 600;
      font-style: italic; }

    .radio-checkbox-container {
      position: relative;
      align-self: flex-start;
      display: flex;
      .cost-difference {
        display: none; }
      .radio-button, .checkbox {
        padding-right: 0.5rem;
        > span {
          margin-left: -8px;
          margin-top: -6px; } } } }

  .checkbox .MuiIconButton-root.Mui-disabled {
    color: #fab62d; }

  .small-view-label {
    display: none; }

  .description-details-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .description {
      flex-grow: 1; } }

  .images-wrapper {
    margin-right: 1rem;
    .image {
      max-height: 150px;
      @include border-radius();
      width: auto;
      cursor: pointer;
      max-width: 100%;
      opacity: 1; } }

  .cis {
    margin-top: 0.3em;
    font-size: 0.8em;
    text-align: right; }

  .button-container {
    display: flex;
    align-items: center;
    margin: 0.2rem 0 1rem 0;
    .select-item.button, .item-added.button {
      align-self: flex-end; }

    .button {
      &.item-added {
        padding-left: 5px; }
      .icon {
        background: $col-secondary;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 12px;
        flex-shrink: 0; } }
    .remove-item-button {
      color: $red;
      cursor: pointer;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      & > svg {
        margin-left: 0.5rem; } }
    .remove-item-button.disabled {
      cursor: auto;
      color: rgba($col-secondary, 0.8); } }

  .item-details {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }

    .quantity, .unit-price, .vat, .total {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      justify-content: center;
      & > span:not(.value) {
        font-weight: 600; }
      & span:first-child {
        padding-bottom: 0.1rem; } }

    .quantity, .unit-price, .vat {
      text-align: center;
      justify-content: center; }
    .unit-price {
      flex: 0 0 120px; }
    .quantity {
      flex: 0 0 50px; }
    .vat {
      flex: 0 0 80px; }

    .total {
      border-left: 1px solid $blue-dark;
      margin-left: 0.5rem;
      padding: 0 0 0 0.5rem;
      flex: 0 0 150px;
      text-align: right;
      & > span:not(.value) {
        display: none; }
      .value {
        display: flex;
        flex-direction: column;
        & > span:first-child {
          font-weight: 600;
          font-size: 1.3rem; }
        .including-vat {
          font-size: 0.8rem; } } } } }


.job-overview.small-view {
  .job-overview-item {
    .job-overview-item-inner {
      flex-direction: column;
      .item-details {
        flex: none;
        @include border-top();
        padding-top: 1.4rem;
        margin: 1rem 0 0 0;

        & > div {
          flex-direction: column; }
        .cis {
          flex-direction: row;
          padding: 0rem 0.5rem;
          .value {
            flex: none;
            padding-left: 0.5rem; } }

        .quantity, .unit-price, .vat, .total {
          flex: none;
          flex-direction: row;
          justify-content: space-between;
          padding-left: 0.5rem;
          padding-right: 0.5rem; }
        .quantity, .unit-price, .vat {
          padding-bottom: 1rem; }
        .total {
          padding: 1rem 0;
          @include border-top();
          border-left: none;
          & > span:not(.value) {
            display: inline-block; } }

        .button-container {
          @include border-bottom();
          .button {
            width: 100%;
            margin-bottom: 1rem; } } } }
    .radio-checkbox-description-container {
      flex-direction: column;
      .radio-checkbox-container .cost-difference {
        display: inline-block; } } } }

.job-overview.small-view .job-overview-items-section:last-child .job-overview-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  .job-overview-item-inner .item-details .button-container {
    border-bottom: none;
    margin-bottom: 0; } }

/* END JOB OVERVIEW ITEM */
/* ACCEPTED SIGNATURE */
.job-overview {
  .accepted-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 1rem; }
  .job-overview-accepted-signature {
    text-align: right;
    line-height: 1.2em;
    p {
      margin: 0; }

    img {
      max-height: 100px;
      max-width: 200px;
      margin-bottom: 1em; } } }
/* END ACCEPTED SIGNATURE */

/* SATISFACTION NOTE DECLARATION */
.job-overview {
  .satisfaction-note-declaration {
    font-size: 0.8em;
    background-color: whitesmoke;
    padding: 0.5rem;

    h3 {
      font-size: 1em; } }
  .satisfaction-note-declaration-response {
    img {
      max-height: 100px;
      max-width: 200px; } }

  & > * {
    margin: 0; }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem; } }
/* END SATISFACTION NOTE DECLARATION */


@media print {
  .job-overview {
    -webkit-print-color-adjust: exact; }


  .job-overview .job-overview-item, .job-overview .job-overview-header, .job-overview .job-overview-notes, .job-overview .job-overview-totals, .job-overview .job-overview-payment-accepted-paid-container {
    page-break-inside: avoid;
    break-inside: avoid; } }


@import '@payaca/styles/variables.sass';

ul.connection-control-benefit-list {
  padding: 20px 0px;
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
  li {
    display: flex;
    svg {
      font-size: 16px;
      padding-top: 2px;
      margin-right: 10px;
      color: $green-dark; } } }


.field .error {
  display: block;
}

.field .label {
  display: inline;
}

.field .validation-container {
  height: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.field .validation-container .error {
  color: red;
}

.field .validation-container .character-count {
  position: absolute;
  right: 0;
}

/* ==================== Validation field ==================== */

.validated-field .input-container {
  border: 1px solid #c4c4c4;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 8px;
  background: #fff;
}

.validated-field .input-container.error {
  border-color: red;
}

.validated-field .input-container:focus-within {
  border-color: #fab62d;
}

.validated-field .input-container input,
.validated-field .input-container textarea {
  border: none;
}

.validated-field .input-container input:focus,
.validated-field .input-container textarea:focus {
  outline: none;
}

.validated-field .input-container .mini-loader {
  width: 20px;
  height: 20px;
}

.show-hide-icon.fa {
  padding: 0px 8px;
}

.validated-field {
  margin-bottom: 10px;
}

.validated-field .pre-input-text {
  padding: 0px 2px 0px 4px;
}

.validated-field .pre-input-text,
.validated-field .post-input-text {
  line-height: 1.4285em;
}

.validated-field .post-input-text {
  padding-left: 2px;
}

.validated-field .with-pre-text input {
  padding-left: 0px;
}

.field input {
  background-color: transparent;
}

.validated-field .react-tel-input .form-control {
  padding: 0px 22px 0px 60px;
  width: 100%;
  font-size: 14px;
}
.validated-field .react-tel-input .form-control:focus,
.validated-field .react-tel-input .selected-flag:focus:before,
.validated-field .react-tel-input .selected-flag.open:before,
.validated-field .react-tel-input .selected-flag:before {
  box-shadow: none;
  border: none;
}

.check-icon,
.cross-icon {
  font-size: 18px;
}
.check-icon {
  color: #44ab44;
}
.cross-icon {
  color: red;
}

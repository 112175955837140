.label-container {
  display: block;
}

.label-container .label {
  text-align: left;
  color: #000;
  width: 100%;
  font-weight: bold;
}

.label-container .tooltip-icon {
  font-size: 0.8em;
  margin: 0 0 0 0.5em;
  padding: 0px;
}

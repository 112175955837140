@import '@payaca/styles/variables.sass';

.dropdown-field {
  .input-wrapper {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 0.5rem; } }

  .field-label {
    margin-bottom: 2px; }

  .MuiInput-root {
    width: 100%;
    background-color: transparent;

    .MuiInput-input {

      .checkbox {
        display: none; } } }

  .MuiSelect-root {
    color: $blue-darker;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0; }

  .MuiSelect-select:focus {
    background-color: transparent; }

  .select-container {
    flex-grow: 1; } }

.MuiList-root {
  color: $blue-darker;

  .MuiListItem-root {

    .checkbox {
      margin-top: -10px;
      margin-bottom: -10px;
      margin-left: -10px; } } }

.MuiListItem-button:hover {
  background-color: $blue-lighter !important; }

.MuiListItem-root.Mui-selected {
  background-color: $blue-light !important;

  &:hover {
    background-color: $blue-light !important; } }

.option-group {
  margin-top: 0.5rem;

  &:first-child {
    margin-top: 0;
    hr {
      display: none; } }

  hr {
    margin: 0;
    margin-left: 1rem;
    margin-right: 1rem; }

  .option-group-name {
    color: rgba($blue-darker, 0.5);
    margin-left: 1rem;
    margin-right: 1rem; } }

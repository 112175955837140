.customer-contact-input-control > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.customer-contact-input-control .checkbox-field {
  padding-top: 0.5rem;
}

.customer-contact-input-control .text-area-field * {
  display: block;
}

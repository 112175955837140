@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.unauthenticated-page-wrapper.split-page-wrapper {

        .lhs-rhs-container {

            .lhs .lhs-inner, .rhs .rhs-inner {
                min-height: 100%;
                padding: 2rem;

                > *:not(:last-child) {
                    margin-bottom: 1rem; }


                .body-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column; } }

            .lhs {
                background-color: $blue-lighter;

                .payaca-logo {
                    max-width: 150px; }

                .body-content {
                    width: 100%;
                    max-width: 450px;
                    margin: 0 auto; }

                .carousel-slide {
                    padding-bottom: 1rem;

                    img {
                        margin-bottom: 2rem; }

                    p, h2 {
                        width: 100%;
                        max-width: 380px;
                        margin-left: auto;
                        margin-right: auto; } } }
            .rhs {
                .body-content {
                    padding: 1rem 0 2rem 0; }
                .title-content {
                    text-align: center;
                    h1 {
                        margin: 0 0 2rem 0; }
                    h2 {
                        margin: 0 0 1.5rem 0; }
                    h3 {
                        margin-bottom: 1rem; }
                    svg {
                        transform: scale(1.2); } } } } }





@media (min-width: 700px) {

    .unauthenticated-page-wrapper.split-page-wrapper {

        .page-wrapper-inner {
            background-color: $blue-lighter;
            padding: 2rem;

            .lhs-rhs-container {
                margin: 0 auto;
                max-width: 1200px;
                background-color: $white;
                @include border-radius-lg();
                box-shadow: 0px 10px 40px 5px rgba($blue-dark, 0.15);
                min-height: 0;
                height: calc(100vh - 4rem); } }

        .lhs .lhs-inner, .rhs .rhs-inner {
            display: flex;
            flex-direction: column;
            height: 100%;

            .body-content {
                flex-grow: 1; } }


        .lhs {
            max-width: 550px; } } }

@import '@payaca/styles/mixins';

.preview-job-action-buttons {
    overflow: hidden;

    &.loader-container {
        @include center-content(); }

    .preview-job-action-buttons-inner {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1rem;
        margin-right: -0.5rem;

        > * {

            margin-right: 0.5rem;
            margin-bottom: 1rem;

            &.icon-button-container {
                flex-basis: calc(50% - 0.5rem);
                max-width: 125px;
                min-height: 100px; }


            &.button.button-style-outsize {
                flex-basis: 100%;
                margin-bottom: 2rem; } } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-side-panel {

  hr {
   border-color: rgba($blue-dark, 0.2); }

  .job-side-panel-form-contents {
    > *:not(:last-child) {
      margin-bottom: 1rem; } } }


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-line-item-control {
  position: relative;
  @include border-radius();

  form {
    position: relative; }

  .form-contents-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    > *:not(:last-child) {
      margin-right: 1rem; } }

  .form-contents {
    background-color: $blue-lighter;
    @include border-radius();
    flex-grow: 1; }

  .is-selected-container {
    width: 1.5rem;
    display: flex;
    justify-content: center;

    .checkbox .MuiCheckbox-root {
      padding: 0; }

    .radio-button .radio-button-select-button {
      margin: 0; } }

  form {
    .job-line-item-form-body {
      padding: 1rem;
      padding-bottom: 0.5rem;

      > *:not(:last-child) {
        margin-bottom: 1rem; } }

    .field-tooltip-wrapper {
      position: relative;

      .input-wrapper, .MuiAutocomplete-root {
        padding-right: 2rem; }

      .tooltip-wrapper {
        position: absolute;
        right: 0.5rem;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;

        > * {
          padding: 0; } } }

    .field-label {
      margin-bottom: 0;
      font-size: 0.8rem;

      .description .tooltip-icon {
        padding: 0;
        color: black;
        font-size: 0.9rem;
        margin-left: 0.5rem;
        margin-top: 2px; } }

    .reference-container {
      max-width: 200px;
      flex-grow: 1; }

    .quantity-container {
      max-width: 75px; }

    .price-container {
      max-width: 100px; }

    .vat-settings-container {
      max-width: 100px;
      min-width: 100px;
      flex-grow: 1; }

    .totals-container {
      font-size: 0.9rem; }

    .MuiSelect-root {
      padding: 2.5px 0; }

    input, textarea, .MuiSelect-root {
      font-size: 0.9rem; }

    .input-wrapper {
      padding-left: 0.75rem;
      padding-right: 0.75rem; }

    .primary-fields-container {
      align-items: flex-start;

      .quantity-price-vat-container {
        margin-top: -1.3rem; }

      .totals-container {
        margin-top: 0.5rem;
        text-align: right; } }

    .actions-container {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      position: absolute;
      right: 0.5rem;
      top: 1rem;
      line-height: 1em;

      .fa-paperclip, .fa-times {
        font-size: 1.2rem; }

      > *:not(:last-child) {
        margin-right: 1rem; }

      .attachments-container {
        cursor: pointer;
        text-decoration: underline;

        .button {
          padding: 0.25rem;
          box-shadow: none; }

        span {
          font-size: 0.9rem; }

        > *:not(:last-child) {
          margin-right: 0.25rem; } } }

    .description-container {
      flex-grow: 1; }

    .job-line-item-form-footer {
      border-top: 1px solid rgba($blue-darker, 0.2);

      .footer-header {
        padding: 0.5rem 1rem;
        align-items: center; }

      .footer-body {
        padding: 0.5rem 1rem 1rem 1rem;

        .profit-container {
          font-size: 0.9rem;
          flex-grow: 1; }

        .supplier-name-container {
          flex-grow: 1;
          max-width: 250px; }

        .supplier-price-container {
          max-width: 100px; }

        .profit-container .label-value-pair {
          text-align: right; }

        .supplier-profit-container {
          align-items: flex-end; } }

      .footer-header {
        .checkbox-field .field-label {
          flex-direction: column;
          align-items: flex-start;
          line-height: 1em;

          > *:not(:last-child) {
            margin: 0;
            margin-bottom: 0.5rem; } }

        .dropdown-field {
          width: 150px; } } } }

  .actions-container {
    .attachments-count {
      font-size: 0.8rem;
      text-align: top; }

    .delete-container {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .fa-times {
        cursor: pointer;
        color: rgba($blue-dark, 0.3); } } }

  .job-line-item-form-footer {
    .collapsible-panel {
      .control-icon-container {
        cursor: pointer; }

      .collapsible-panel-header {
        padding: 0;
        padding-right: 1rem;
        cursor: default;
        transition-property: none;
        display: flex;
        align-items: center; } }

    .checkbox-field {
      margin: 0;

      > *:not(:last-child) {
        margin-right: 0.5rem; } }

    .checkboxes-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -0.5rem;
      margin-right: -1rem;

      > * {
        margin-bottom: 0.5rem;
        margin-right: 1rem; } } }

  &.sm-view .primary-fields-container {
    flex-direction: column;
    align-items: stretch; }

  &.xs-view .primary-fields-container {
    flex-direction: column;
    align-items: stretch;

    > *:not(:last-child) {
      margin: 0;
      margin-bottom: 1.5rem;
      width: 100%; } }

  &.sm-view {
    .primary-fields-container > *:not(:last-child) {
      margin: 0;
      margin-bottom: 1.5rem;
      width: 100%; }

    .supplier-profit-container {
      flex-wrap: wrap; } }

  &.xs-view {
    .supplier-profit-container {
      flex-wrap: wrap; }

    .job-line-item-form-body {
      margin-top: 2rem; }

    .quantity-price-vat-container {
      flex-wrap: wrap; }

    form .job-line-item-form-footer .footer-header {
      flex-direction: column;
      align-items: flex-start; }

    .footer-header > *:not(:last-child) {
      margin: 0;
      margin-bottom: 0.5rem; } } }

/* SMALL VIEW */

/* END SMALL VIEW */

/* XS VIEW */

/* END XS VIEW */

.quantity-control {
  justify-content: center;

  > span {
    display: inline-block;
    text-align: center;
    min-width: 1rem; }

  .button {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 0.8rem;
    padding: 0; }

  .input-wrapper {
    box-shadow: none; }


  .basic-field {
    width: 45px;

    .input-wrapper {
      padding-left: 10px;
      padding-right: 0px;

      input {
        text-align: center; } } } }

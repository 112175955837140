
.listed-users-page {
    .listed-users-table-container {
        height: 100%;

        .listed-users-table {
            table {
                padding: 0 1.5rem 0 1rem; }

            .pagination-control {
                padding: 1rem 2rem 2rem 4rem; } }


        .row-selection-table-cell {
            opacity: 0;
            transition:  opacity 0.3s;
            pointer-events: none; }


        &.bulk-actions-mode {
            .row-selection-table-cell {
                opacity: 1;
                pointer-events: all; } } } }


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.deal-finance-item {
    padding: 0;
    overflow: hidden;
    background-color: $blue-lighter;

    .title {
        padding: 1rem;
        display: flex;
        margin: 0; }

    .content {
        padding: 1rem; }

    > hr {
        display: none; }

    &.approved {
        .title {
            background-color: $green; } }

    &.pending {
        .title {
            background-color: $blue-light; } }

    &.failed {
        .title {
            background-color: $red;
            color: $white; } } }




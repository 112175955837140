.trial-period-expiry-holding-page .trial-period-expiry-callout-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trial-period-expiry-holding-page .contact-us-callout .children-container {
  text-align: center;
}

.trial-period-expiry-holding-page .contact-us-callout .children-container ul {
  list-style-type: none;
  padding: 0;
}

.unique-finance-link-content-panel .unique-finance-link-container {
  border: 1px solid black;
  padding: 1rem;
}

.unique-finance-link-content-panel .button {
  margin: 0;
}

.unique-finance-link-content-panel.small-view .flex-container {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.unique-finance-link-content-panel.small-view
  .flex-container
  > *:not(:last-child) {
  margin: 0;
  margin-bottom: 1rem;
}

.unique-finance-link-content-panel.small-view .unique-finance-link-container {
  width: 100%;
}

.update-payment-method-control {
  padding: 2rem 0;
}

.update-payment-method-control .actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-payment-method-control .actions-container > * {
  width: 250px;
}

.update-payment-method-control .actions-container .button {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.update-payment-method-control .actions-container > *:not(:last-child) {
  margin-right: 1rem;
}

.update-payment-method-control .update-payment-method-container {
  margin: 0 auto;
  max-width: 600px;
}

.update-payment-method-control .feedback-message {
  justify-content: center;
  margin-top: 0.5rem;
}

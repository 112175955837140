@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.customer-address-input-control {
  .address-lookup-control-container {
    align-items: flex-end; }

  .address-lookup-container {
    .label-value-pair {
      .label {
        font-weight: normal; }

      .value {
        font-weight: bold; } }

    > *:not(:last-child) {
      margin-bottom: 0.5rem; } }

  > *:not(:last-child) {
    margin-bottom: 0.5rem; }

  .enter-address-manually-trigger-container {
    display: flex;
    justify-content: flex-end; }

  .address-controls-container {
    display: flex;
    justify-content: flex-end;

    .fa-times {
      color: rgba($blue-dark, 0.5);
      cursor: pointer; }

    > *:not(:last-child) {
      margin-right: 2rem; } }

  .address-lookup-results-container {
    list-style: none;
    padding: 0;
    border: 1px solid  $blue-dark;
    max-height: 100px;
    overflow-y: scroll;
    @include border-radius();
    background-color: $white; }

  .address-lookup-result {
    cursor: pointer;
    padding: 0 0.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($blue-darker, 0.2); }

    &:hover {
      background-color: $blue-lighter; } }

  .error-message {
    font-size: 0.9rem;
    color: #c4c4c4; }

  &.sm-view .address-lookup-control-container {
    display: block;

    > *:not(:last-child) {
      margin: 0;
      margin-bottom: 0.5rem; }

    .button {
      width: 100%; } } }

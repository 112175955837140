@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.listed-deals-table {
  background-color: $white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;



  .pagination-control {
      padding: 1rem 0; }

  .styled-table-wrapper {
    flex-grow: 1; }

  table.styled-table {
    border-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;

    .no-results-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        margin-bottom: 20px; } }

    .loader-container {
      @include center-content(); }

    td, th {
      padding: 0.75em;
      vertical-align: middle; }

    th {
      padding-top: 0; }



    tbody {

      &::before {
        content: '';
        display: block;
        height: 10px; }

      &.clickable {
        cursor: pointer;

        td {
            cursor: pointer; }

        &:hover {
            td:not(.tr-exclude) {
                background-color: $blue-light; } } }

      tr {

        td, td:not(.tr-exclude):first-child, td:not(.tr-exclude):last-child {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px; }

        &:first-child {
          td:first-child {
            border-top-left-radius: 10px; }

          td:last-child {
            border-top-right-radius: 10px; } }

        &:not(:first-child) {
          td {
            border-top: none; }

          td:first-child {
            border-left: none; } }

        &:last-child {
          td:last-child {
            border-bottom-right-radius: 10px; } }

        &:not(:last-child) {
          td:not([rowspan]) {
            border-bottom: 1px solid rgba($blue-dark, 0.2); } }

        td[rowspan] {
          border-right: 1px solid rgba($blue-dark, 0.2);

          &:first-child {
            border-bottom-left-radius: 10px; } }

        td.unmatched-item {
          color: #cbd1d6;

          & .badge {
            opacity: 0.3; } } }

      .customer-table-cell {
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        > * {
          display: block;

          &:not(:last-child) {
            margin-bottom: 0.25rem; } } } } }

  &.sm-view {
    .scrollable-table-wrapper {
      overflow-x: scroll; } } }

.deal-overview {



    > *:not(:last-child) {
        margin-bottom: 2rem; }

    > .content-panel {
        hr {
            display: none; }

        .content {
            padding-left: 10px;
            padding-right: 10px; } } }

@import '@payaca/styles/variables.sass';

.item-group-header {
  display: flex;
  align-items: center;
  font-size: 0.9rem;

  textarea {
    font-size: 0.9rem; }

  .item-group-header-description {
    flex-grow: 1; }

  .fa-times {
    font-size: 18px;
    cursor: pointer;
    color: rgba($blue-dark, 0.3); }

  .delete-container {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem; }

  .item-group-description-add-button {
    .span {
      text-decoration: underline; }

    .fa-pen {
      margin-left: 1rem; } } }

.preview-and-send-job-control {
    .job-overview-container, {
        > .content-panel {
            border-radius: 0; } }

    .preview-content {
        >*:not(:last-child) {
            margin-bottom: 3rem; } }

    .sidebar-content {

        > *:not(:last-child) {
            margin-bottom: 2rem; }

        button.button-style-outsize {
            width: 100%; } } }

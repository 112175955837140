.searchable-table-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0px 10px;
}

.searchable-table-container .styled-table-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.searchable-table-container .pagination {
  border: none;
  border-top: 1px solid #fabb00;
  border-radius: 0;
}

.searchable-table-container .pagination {
  border: none;
  border-top: 1px solid #fabb00;
  border-radius: 0;
}

.searchable-table-container .styled-table-wrapper .scrollable-table-wrapper {
  flex-grow: 1;
}

.searchable-table-container table.styled-table td {
  padding: 0.3em;
  vertical-align: middle;
}

.table-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.table-management-header .button:not(:last-child) {
  margin-right: 10px;
}

.table-management-header .button {
  margin: 0;
  box-shadow: none;
}

.table-management-header.bulk-actions {
  display: flex;
  justify-content: flex-end;
}

.column-name.price {
  min-width: 150px;
}

.search-icon {
  height: 30px;
  width: 30px;
  padding: 5px;
}

.search-bar {
  width: 40%;
}

.search-bar .basic-field {
  width: 100%;
}

.search-bar .basic-field .input-wrapper {
  border: none;
  border-bottom: 2px solid #ffca2d;
  background-color: transparent;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  color: #ffca2d;
}

.column-sort-icon {
  margin: 0px 10px;
  font-size: 14px;
}

.quick-action {
  padding: 5px;
  border: 1px solid #000;
  margin: 0 5px;
}

.check-icon-cell,
.action-icon-cell,
.table-element {
  text-align: center;
}

.column-sort-icon,
tbody tr:hover:not(.disabled) {
  cursor: pointer;
}

.action-icon-cell .option-icon {
  width: 70px;
  height: 18px;
}

.action-icon-cell .action-menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchable-table-container .check-box-icon {
  border: 1px solid black;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.searchable-table-container .check-icon {
  font-size: 14px;
}

.table-element.price {
  text-align: right;
  padding-right: 2%;
}

.table-element.status {
  padding: 5px;
}

.table-element div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-name.description,
.table-element.description {
  max-width: 500px;
}

/* medium */
@media screen and (max-width: 1280px) {
  .column-name.description,
  .table-element.description {
    max-width: 300px;
  }
}

/* small */
@media screen and (max-width: 969px) {
  .column-name.description,
  .table-element.description {
    max-width: 100px;
  }
}

.searchable-table-container.items .disabled {
  color: #c4c4c4;
  cursor: auto;
}

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.collapsible-sidebar {
    position: relative;
    transition: width 0.3s ease-in-out;
    background-color: $blue-darker;
    height: 100%;

    .collapsible-sidebar-wrapper {
        overflow: hidden;
        height: 100%; }

    .collapsible-sidebar-inner {
        transition: width 0.3s ease-in-out, margin 0.3s ease-in-out;
        overflow-y: scroll;

        height: 100%;
        overflow: hidden; }

    .expand-collapse-control {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        bottom: 50px;
        transition: right 0.3s ease-in-out,background-color 0.3s ease-in-out;
        right: -20px;
        background-color: inherit;
        border: 3px solid $white;
        cursor: pointer;
        @include center-content();


        .expand-collapse-control-inner {
            position: relative;
            width: 12.5px;
            height: 20px;

            .fa-chevron-right {
                padding-left: 2px; }

            .fa-chevron-left {
                padding-right: 2px; } } }

    &.collapse-direction-left {
        .expand-collapse-control {
            right: -20px; } }

    &.collapse-direction-right {
        .expand-collapse-control {
            left: -20px; } }


    .collapse-control, .expand-control {
        width: 100%;
        font-size: 20px;
        color: $white;
        position: absolute;
        transition: opacity 0.3s ease-in-out; }

    .collapse-control {
        opacity: 1;
        margin: 0 auto; }
    .expand-control {
        opacity: 0;
        margin: 0 auto; }

    &.collapsed {
        .collapse-control {
            opacity: 0; }
        .expand-control {
            opacity: 1; } }


    &.hidden {
        &.collapse-direction-left {
            .expand-collapse-control {
                right: 0; } }

        &.collapse-direction-right {
            .expand-collapse-control {
                left: 0; } } } }





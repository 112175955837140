.training-videos-content-panel h4 {
  margin: 0;
}

.training-videos-content-panel > *:not(:last-child) {
  margin-bottom: 1rem;
}

.training-videos-content-panel .fa-arrow-right {
  margin-left: 0.5rem;
  transition: margin-left 250ms;
}

.training-videos-content-panel a:hover .fa-arrow-right {
  margin-left: 1rem;
}

@import '@payaca/styles/mixins';
.download-app-page {
  overflow-y: scroll;
  height: 100%;
  .download-app-page-inner {
    padding: 2rem;

    .download-app-title {
      font-size: 1.1rem;
      margin: 1rem;
      text-align: center; }

    .download-app-button-container {
      display: flex;
      justify-content: center;

      .button {
        margin-bottom: 1rem;
        width: 200px; } }

    .download-apps {
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      text-align: center;

      img {
        height: 50px;
        margin: 10px; } }

    .phone-image {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      display: block; } } }


.form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
}

.form .button-container {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.form-error {
  color: red;
  margin: 5px 20px;
}

.form .submit {
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}

.form .section-information {
  padding: 0px 0px 10px 0px;
  font-size: 0.9em;
}

.form .button {
  min-width: 250px;
}

.preview-job-header {

    > * {
        flex-basis: 0;
        flex-grow: 2; }

    h1 {
        margin: 0;
        text-align: center;
        font-size: 1.5rem;
        flex-grow: 1; }

    .assignment-control-container {
        display: flex;
        justify-content: flex-end;
        .dropdown-field {
            display: flex;

            .input-wrapper {
                width: 200px; }

            label {
                font-weight: 600;
                margin-bottom: 0;
                margin-right: 0.5rem;
                white-space: nowrap; } } }

    &.sm-view {
        flex-direction: column;

        > *:not(:last-child) {
            margin: 0;
            margin-bottom: 1rem; } } }



.watch-demo-modal.modal {

    .MuiDialog-paper {
        background-color: black; }

    .close-control {
        z-index: 1; }

    .body-content {
        z-index: 0; }

    .MuiDialogContent-root > *:first-child,
    .MuiDialogContent-root > *:last-child,
    .body-content {
        padding: 0; }

    .video-iframe-wrapper {
        position: relative;
        width: 100%;
        padding: 56.25% 0 0 0; }

    .video-iframe {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

table.styled-table td.quick-actions-table-cell {
  text-align: center;
}

table.styled-table td.quick-actions-table-cell {
  width: 2.5rem;
  max-width: 2.5rem;
  min-width: 2.5rem;
}

table.styled-table td.quick-actions-table-cell.clickable {
  cursor: pointer;
}

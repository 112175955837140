@import '@payaca/styles/variables.sass';

.header-navigation {
    hr {
        margin-left: 1rem;
        margin-right: 1rem; }

    .navigation-item {
        padding: 0.25rem 1rem;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out;

        &.disabled {
            opacity: 0.5; }

        &:hover {
            background-color: $blue-lighter; }

        .fa-exclamation-triangle {
            color: $yellow-dark; } } }

@import '@payaca/styles/variables.sass';
.trial-period-countdown-banner {
  background-color: #0872ED;
  padding: 0.5rem;
  text-align: center;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  flex-wrap: wrap;

  .button.upgrade-account {
    color: $white;
    margin-left: 1rem; } }

@import '@payaca/styles/variables';

.business-information-form-section {
  .titled-content-panel .content > *:not(:last-child) {
    margin-bottom: 1rem; }

  .field-group > .content-panel {
    background: $blue-lighter;
    .content-panel {
      background: $blue-light;
      .content-panel {
        background: $blue-lighter; } } } }

/* SMALL VIEW STYLES */

.business-information-form-section.sm-view .field-group > .flex-container {
  flex-direction: column; }

.business-information-form-section.sm-view .field-group > .flex-container > *:not(:last-child) {
  margin-bottom: 0.5rem; }

.business-information-form-section.sm-view .director-form-subsection .content > .flex-container {
  flex-direction: column; }

.business-information-form-section.sm-view .director-form-subsection .content > .flex-container > *:not(:last-child) {
  margin-bottom: 1rem; }

.business-information-form-section.sm-view .company-contact-form-subsection .content > .flex-container {
  flex-direction: column; }

.business-information-form-section.sm-view .company-contact-form-subsection .content > .flex-container > *:not(:last-child) {
  margin-bottom: 0.5rem; }

/* END SMALL VIEW STYLES */

.customer-email-control .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-email-control .field-container {
  max-width: 350px;
}

.customer-email-control.sm-view .form-contents {
  flex-direction: column;
}

.customer-email-control.sm-view .form-contents > *:not(:last-child) {
  margin: 0;
  margin-bottom: 0.5rem;
}

.customer-email-control.sm-view .button-container {
  height: auto;
  justify-content: flex-end;
}

.customer-email-control.sm-view .field-container {
  max-width: 100%;
}

.subscription-exceeded-banner {
  background-color: #bfd8f8;
  padding: 0.5rem 1.5rem;
  text-align: center;
}

.subscription-exceeded-banner > * {
  white-space: nowrap;
}

.subscription-exceeded-banner .fa-chevron-right {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.subscription-exceeded-banner a {
  font-family: Poppins;
  font-weight: 600;
  margin-left: 1rem;
  color: #0f69d1;
}

@media (max-width: 650px) {
  .subscription-exceeded-banner > * {
    width: 100%;
    display: block;
    white-space: normal;
  }

  .subscription-exceeded-banner a {
    margin-left: 0rem;
    margin-top: 0.3rem;
  }
}

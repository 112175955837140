.sent-invoice-value-chart .invoice-pending-value {
  fill: #00b6cb;
  background-color: #00b6cb;
}

.sent-invoice-value-chart .invoice-paid-value {
  background-color: #0072f0;
  fill: #0072f0;
}

.sent-invoice-value-chart .internal-bar-label {
  fill: white;
}

.sent-invoice-value-chart .invoice-pending-value-bar-label.external-bar-label {
  fill: #00b6cb;
}
.sent-invoice-value-chart .invoice-paid-value-bar-label.external-bar-label {
  fill: #0072f0;
}

.sent-invoice-value-chart > *:not(:last-child) {
  margin-bottom: 1rem;
}

.sent-invoice-value-chart .categories-key {
  margin-left: 80px;
}

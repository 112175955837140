@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.header-information-resources-navigation {

    .headway-widget-badge-container {
        @include center-content();

        .HW_badge_cont {
            max-height: 20px;
            max-width: 20px; }


        .HW_badge {
            line-height: 22px;
            background: $yellow-dark;
            height: 20px;
            width: 20px;
            top: 0;
            left: 0; } }


    .navigation-item {
        padding: 0.25rem 1rem;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out;

        &.disabled {
            opacity: 0.5; }

        &:hover {
            background-color: $blue-lighter; }

        .fa-exclamation-triangle {
            color: $yellow-dark; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.company-settings-container .legal-business-name-information {
  margin: 0 1.5rem;
  margin-top: 1rem;
  display: inline-block; }

.company-settings-container .legal-business-name-information .clickable {
  font-weight: bold;
  cursor: pointer; }

.company-settings-container .dropdown-field label {
  font-weight: bold; }

/* EMAIL CUSTOMISATION */
// .company-settings-container .tab-content-container .email-customisation
//   display: flex
//   flex-wrap: wrap

// .company-settings-container .tab-content-container .email-customisation .email-template
//   position: relative
//   width: 50%
//   padding: 0 1rem 1rem
// .company-settings-container .tab-content-container .email-customisation .email-template .reset-icon-wrapper.spin
//   animation: spin 0.4s ease-out infinite

// .company-settings-container .tab-content-container .email-customisation .email-template .reset-icon-wrapper
//   position: absolute
//   bottom: 0.5rem
//   left: 1rem
//   cursor: pointer
//   padding: 0.5rem


.company-settings-container .tab-content-container .email-customisation textarea {
  text-align: center; }

.company-settings-container .tab-content-container .email-customisation .text-area-field .field-label {
  flex-direction: column;
  align-items: flex-start; }

/* Sub sections */
.settings-sub-section {
  @include container-background();
  h4 {
    margin: 0 0 0.5rem;
    &:not(:first-child) {
      margin-top: 2rem; } }

  p {
    margin: 0.5rem 0 1rem 0; }
  &:not(:first-child) {
    margin-top: 1rem; } }

/* BRANDING */
.company-settings-branding {
  .logo-upload-wrapper {
    display: flex;
    justify-content: center;
    .drag-drop-wrapper {
      height: 150px; }
    img {
      @include border-radius();
      max-height: 190px; } }

  .button-container {
    display: flex;
    justify-content: center;
    padding: 2rem 0 1rem 0;
    .button.remove-logo {
      color: $red; } }

  .marketing-description {
    font-size: 0.8rem;
    padding-bottom: 1rem; }
  .persisted-drag-drop-file-control-inner {
    background-color: $blue-light; } }

/* EMAIL CUSTOMISATION */
.company-settings-email-customisation {
  .settings-sub-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0; } } }

.email-template-modal {
  .template-wrapper {
    @include box-shadow;
    @include center-content();
    @include container-background();
    background: $white;
    flex-direction: column;
    padding: 1.5rem;
    .account-logo {
      width: 184px;
      margin-bottom: 0.5rem; }
    .text-area-field {
      width: 100%;
      max-width: 490px;
      padding: 1rem;
      .input-wrapper {
        border: 1px solid rgba($col-secondary, 0.2);
        @include border-radius;
        textarea {
          text-align: center; } } }
    .button {
      width: 280px;
      margin: 0.5rem; } }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    position: relative;

    .reset-container {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .reset-icon {
        height: 20px;
        width: 20px;
        margin-right: 0.5rem; }
      .reset-icon.spin {
        animation: spin 0.4s ease-out infinite; } } } }

@keyframes spin {}
from {
  transform: rotate(0deg); }

to {
  transform: rotate(360deg); }


/* TERMS */
.company-settings-terms {
  .text-area-field {
    margin-bottom: 1rem; }
  .persisted-drag-drop-file-control-inner {
    background-color: $blue-light; } }


/* COMPANY INFO & GETTING PAID */
.company-settings-company-info, .company-settings-getting-paid {
  .settings-sub-section {
    > .basic-field {
      &:not(:last-child) {
        margin-bottom: 1rem; }
      &:last-child {
        margin-bottom: 0.5rem; } }
    .loqate-advisory-phone-input-field, .loqate-advisory-email-input-field {
      margin-bottom: 1rem; } } }

/* FINANCE & REMINDERS */
.company-settings-reminders, .company-settings-finance {
  .settings-sub-section .switch-field {
    &:not(:last-child) {
      margin-bottom: 1rem; }
    &:last-child {
      margin-bottom: 0.5rem; } } }


@media (max-width: 800px) {
  .company-settings-container .tab-content-container .email-customisation {
    flex-wrap: no-wrap;
    flex-direction: column; }

  .company-settings-container .tab-content-container .email-customisation .email-template {
    width: 100%; } }


.banner {
  position: absolute;
  top: 0px;
  padding: 5px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  z-index: 10;
}

.banner.error {
  background: red;
}

.banner .banner-message-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
}

.banner .close-icon {
  cursor: pointer;
  font-size: 22px;
  margin: 10px 10px 0px 0px;
}

.banner .type-icon {
  font-size: 28px;
  margin-right: 10px;
}

.banner .close-icon,
.banner .type-icon {
  align-self: flex-start;
}

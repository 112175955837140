.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 20px;
}

.empty-state img {
  width: 35%;
  max-width: 150px;
  min-width: 80px;
}

.empty-state .text {
  margin-bottom: 50px;
  text-align: center;
}

.empty-state img.arrow-icon {
  width: 25%;
  max-width: 150px;
  min-width: 50px;
}

.empty-state img.arrow-icon.up {
  transform: rotate(270deg);
  position: absolute;
  top: 10px;
  right: 110px;
}

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-attachments-control {
  .file-upload-field .input-wrapper {
    border: none;
    padding: 0;
    box-shadow: none; }

  .validated-field-wrapper {
    max-width: 600px;
    margin: 0 auto; }

  .file-upload-field {

    .accepted-file-types {
      margin: 0; } }

  .persisted-files .persisted-file-control {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .file-name, .basic-field {
      flex-grow: 1; } }

  .file-upload-persist-remove-control .file-list-container {
    li:not(:last-child), ul:not(:last-child) {
      margin-bottom: 0.5rem; } } }

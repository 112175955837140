@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';


.create-subscription-page-content {
  padding-bottom: 4rem;

  > *:not(:last-child) {
    margin-bottom: 2rem; }

  .create-subscription-guidance-prompts-container {
    margin-bottom: 4rem; }

  .view-comparison-chart-trigger-container {
    width: 100%;
    @include center-content(); }

  .subscription-plans-container-element {
    background-color: transparent;
    transition: background-color 1s, border-color 1s;
    @include border-radius();
    padding: 1rem;
    border: 2px solid transparent;

    > *:not(:last-child) {
      margin-bottom: 2rem; }

    &.focused {

      background-color: rgba($blue, 0.2);
      transition: background-color 0.2s, border-color 0.2s; } } }

.maintenance-banner {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background: #ffca2d;

  .separator {
    border-bottom: 1px solid #263e59;
    margin: 1rem 20%; }

  button.dismiss, button.reload {
    display: inline-block;
    margin-left: 0.8rem; }

  .hammer-icon-wrapper .hammer-icon {
    font-size: 20px; }

  .hammer-icon-wrapper, .close-icon-wrapper {
    color: #263e59; }

  .inner-banner-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    text-align: center; }

  // Hidden banner
  &.hidden {
    position: absolute;
    z-index: 1000;
    background: none;
    padding: 0.5rem;

    .hammer-icon-wrapper {
      transition: color 0.5s ease-in;
      color: #ffca2d;
      padding: 0;
      cursor: pointer; } } }

.split-page-wrapper {

    .page-wrapper-inner {
        min-height: 100vh;

        .lhs-rhs-container {
            min-height: 100vh;
            display: flex;
            flex-direction: column;

            .lhs, .rhs {
                flex-basis: 50%;
                flex-grow: 1; }

            .lhs .lhs-inner, .rhs .rhs-inner {
                padding: 1rem; } } } }



@media (min-width: 700px) {

    .split-page-wrapper {
        overflow-y: hidden;

        .page-wrapper-inner {
            overflow-y: hidden;

            .lhs-rhs-container {
                flex-direction: row;
                overflow-y: hidden;

                .lhs, .rhs {
                    overflow-y: scroll; } } } } }



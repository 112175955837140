@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.personal-information-form-container {


    form {
        > *:not(:last-child) {
            margin-bottom: 1rem; }

        hr {
            margin-top: 2rem;
            margin-bottom: 2rem !important; } }

    .name-image-container {
        > * {
            flex-basis: 50%; }

        .name-container {
            > * {
                margin: 0;

                &:not(:last-child) {
                    margin-bottom: 1rem; } } }

        .image-upload-container {
            @include center-content(); } }

    .actions-container {
        .button-style-outsize {
            width: 280px; }

        .feedback-message {
            width: 280px;
            margin-top: 0.5rem; } }

    .change-password-trigger-container {
        margin-top: 2rem; }


    &.sm-view {
        .name-image-container {
            flex-direction: column-reverse;

            > * {
                width: 100%;

                &:not(:last-child) {
                    margin-right: 0; } } }

        .image-upload-container {
            margin-bottom: 1rem; } } }

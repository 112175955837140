@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.contact-us-modal {
    text-align: center;

    .body-content > *:not(:last-child) {
        margin-bottom: 2rem; }

    .contact-methods-container {
        display: flex;
        justify-content: center;
        align-items: stretch;

        > *:not(:last-child) {
            margin-right: 2rem; }

        .contact-method {
            background-color: $blue-light;
            @include border-radius();
            padding: 1.5rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 180px;
            text-decoration: none;

            svg {
                margin-bottom: 0.5rem; } } }

    .feedback-prompt-container {
        > * {
            margin: 0;
            line-height: 1.5em; }

        a {
            margin: 1.5rem auto 0 auto;
            text-decoration: none;
            display: inline-block;

            button {
                min-width: 280px; } } }

    .socials-container {
        margin-bottom: 1rem;

        .social-icons-container {
            font-size: 1.8rem;
            justify-content: center;

            > *:not(:last-child) {
                margin-right: 2rem; } } } }




@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.header-search-control {
    display: flex;
    align-items: center;

    .header-search-control-inner {
        display: flex;
        align-items: center;
        width: 100%;

        > *:not(:last-child) {
            margin-right: 0.5rem; }

        .search-icon-container {
            @include center-content();
            width: 30px;
            height: 30px;

            svg {
                width: 22px;
                height: 22px; } } }


    &.sm-view {
        .header-search-control-inner {
            .close-icon-container {
                cursor: pointer; } }

        &.collapsed {
            .header-search-control-inner {
                .search-icon-container {
                    cursor: pointer; } } }

        &:not(.collapsed) {
            width: 100%; } } }

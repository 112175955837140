@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.payment-method-card {
    background-color: $blue-dark;
    @include border-radius();
    width: 366px;
    height: 220px;
    padding: 2.25rem;
    background-size: cover;
    background-position: center center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    letter-spacing: 1px;

    .card-number-digits {
        > *:not(:last-child) {
            margin-right: 0.5rem; } }


    label {
        font-size: 0.75rem;
        text-transform: uppercase;
        margin-bottom: 0.25rem;
        display: block; } }

.add-new-deal-item-control-modal {
    .body-content {
        > *:not(:last-child) {
            margin: 0;
            margin-bottom: 1rem; }


        p {
            text-align: center;
            margin-bottom: 1rem; } } }

/* Colours */

$white: #ffffff;

$grey-mid: #919191;

$yellow-light: #fff2cc;
$yellow: #ffca2d;
$yellow-dark: #fabb00;

$blue-lighter: #edf2f7;
$blue-light: #dbe5f0;
$blue: #0872ED;
$blue-dark: #36577d;
$blue-darker: #263e59;
$blue-facebook: #4968AD;
$blue-xero: #1AB4D7;

$green: #75e582;
$green-dark: #4ea83d;
$green-quickbooks: #399536;

$red: #d93a3a;
$red-zapier: #FF4A00;

$purple-stripe: #6772E5;

/* Primary and secondary colours */

$col-primary: $yellow;
$col-primary-dark: $yellow-dark;

$col-secondary: $blue-dark;
$col-secondary-dark: $blue-darker;

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.body-with-sidebar-content-wrapper {
    height: 100%;
    width: 100%;

    > *:not(:last-child) {
      margin-right: 2rem; }

    .main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%; }


    .sidebar {
      @include border-radius();
      background-color: $blue-lighter;
      padding: 1rem;
      width: 385px;
      overflow-y: scroll; }

    .body-content {
      flex-grow: 1;
      overflow-y: scroll; }

    .title-bar {
      display: flex;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $blue-light;
      min-height: 60px;

      h1 {
          padding: 0;
          margin: 0;
          font-size: 20px; } } }

.companies-house-company-preview
  .multiline-label-value-pair
  .label-value-pair
  > * {
  display: block;
}

.companies-house-company-preview {
  background-color: #f1f6fd;
  border: 1px solid #7eb0f1;
  max-width: 550px;
  padding: 1.5rem;
  border-radius: 0.35rem;
}

.companies-house-company-preview h3 {
  margin: 0;
}

.companies-house-company-preview > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.companies-house-company-preview .fa-building {
  font-size: 1.8rem;
}

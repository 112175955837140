@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.profile-page-wrapper {
    .body-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;

        > .divider {
            display: none;
            width: 0px;
            border-left: 1px solid $blue-light;
            margin: 1.5rem 0; }


        .lhs, .rhs {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: 100%;

            > hr {
                margin: 0 3rem; }

            .title-bar {
                padding: 2rem 3rem 0rem 3rem; }

            .lhs-content, .rhs-content {
                padding: 1.5rem 3rem;
                flex-grow: 1; } }

        .lhs {

            .title-bar {

                h1 {
                    padding: 0;
                    margin: 0;
                    font-size: 20px; } }

            .lhs-content {
                .profile-progress {
                    .profile-progress-inner {
                        display: flex;
                        flex-direction: row;
                        h4 {
                            margin-bottom: 0.5rem; }
                        p {
                            margin-top: 0; }
                        .profile-progress-circle {
                            width: 120px;
                            height: 120px;
                            border: 4px solid $col-primary;
                            border-radius: 50%;
                            margin-right: 1rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span {
                                font-size: 2.2rem;
                                font-weight: 600; } } }
                    & > p {
                        @include container-background();
                        margin: 1rem 0 0; } } } }

        .rhs {
            .title-bar {
                h2 {
                    padding: 0 0 1.5rem;
                    margin: 0;
                    font-size: 20px; }
                hr {
                    margin: 0; } } } } }




@media (min-width: 1000px) {

    .profile-page-wrapper {
        .body-content {
            flex-direction: row;

            > .divider {
                display: block; }

            .lhs {
                max-width: 500px;
                flex-basis: 450px;
                flex-shrink: 1; }

            .rhs {
                flex-grow: 1; }

            .lhs, .rhs {
                .lhs-content, .rhs-content {
                    overflow-y: scroll; } } } } }

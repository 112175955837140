.authority-to-search-form-section p {
  font-size: 1rem;
}

/* SMALL VIEW STYLES */

.authority-to-search-form-section.sm-view
  .field-group
  > .flex-container:not(.checkbox-container) {
  flex-direction: column;
}

.authority-to-search-form-section.sm-view
  .field-group
  > .flex-container:not(.checkbox-container)
  > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* END SMALL VIEW STYLES */

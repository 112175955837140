.deal-information-control {
    width: 100%;

    h3 {
        margin-top: 0;
        margin-bottom: 0; }

    .deal-information {
        > *:not(:last-child) {
            margin-bottom: 0.5rem; }

        .deal-description {
            .editable-element-control {
                width: 100%;
                max-width: 600px;

                .validated-field-wrapper {
                    flex-grow: 1; } } }

        .deal-reference {
            .basic-field {
                input {
                    font-size: 20px;
                    font-weight: 600; } } } } }

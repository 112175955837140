.forgot-password-page {
  .body-content {
    max-width: 400px;
    margin: 0 auto;

    > *:not(:last-child) {
      margin-bottom: 2rem; }

    .title-introduction-container {
      text-align: center;

      * {
        margin: 0; }

      > *:not(:last-child) {
        margin-bottom: 1rem; } } }

  .forgot-password-form-container {
    max-width: 300px;
    margin: 0 auto; }

  .sign-up-prompt {
    button {
      display: inline; } } }



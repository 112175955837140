.send-job {
  height: 100%;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 2rem; }

  .addressee-and-subject-container > *:not(:last-child) {
    margin-bottom: 1rem; } }

.send-job-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  .button {
    width: 250px; } }
.email-content-container {
  border-radius: 0; }


.customer-email-preview-wrapper {
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center; }

.customer-email-preview {
  width: 500;
  min-width: 50%;

  img.logo {
    max-width: 200px;
    margin-bottom: 1rem; }

  .button {
    margin: 1em auto; }

  .text-area-field textarea {
    text-align: center; } }

.send-invoice-error-modal .send-invoice-error-modal-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem; }

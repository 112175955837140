
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.basic-page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .page-wrapper-inner {
        flex-grow: 1;
        background-color: $blue-lighter;
        width: 100%;

        .page-content {
            margin: 0 auto;
            max-width: 1200px;
            background-color: $white;
            box-shadow: 0px 10px 40px 5px rgba($blue-dark, 0.15);
            min-height: 100vh;
            padding: 2rem;
            display: flex;
            flex-direction: column;

            .header-content {
                margin-bottom: 1rem; }

            .body-content {
                flex-grow: 1;
                @include center-content();
                min-height: 0; } } }


    .payaca-logo {
        max-width: 150px; } }

@media (min-width: 700px) {
    .basic-page-wrapper {

        .page-wrapper-inner {
            padding: 2rem;

            .page-content {
                @include border-radius-lg();
                min-height: calc(100vh - 4rem); } } } }


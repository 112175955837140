.listed-line-item-groups {
    .listed-line-item-groups-inner {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1rem;
        margin-right: -1rem;
        justify-content: stretch;

        .listed-line-item-group-tile {
            width: calc(25% - 1rem);
            max-width: 400px;
            margin-right: 1rem;
            margin-bottom: 1rem; } }

    &.sm-view {
        .listed-line-item-groups-inner {
            .listed-line-item-group-tile {
                width: calc(33.333% - 1rem); } } }

    &.xs-view {
        .listed-line-item-groups-inner {
            .listed-line-item-group-tile {
                width: calc(100% - 1rem); } } } }

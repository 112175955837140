@import '@payaca/styles/mixins.sass';
.colour-picker-field {
  .field-label label {
    margin: auto; }

  .colour-picker-wrapper {
    justify-content: center;
    .sketch-picker {
      box-shadow: none !important;
      width: 220px !important;
      & > div:first-child {
        @include border-radius-sm(); }
      input {
        @include border-radius-sm();
        box-shadow: 0 4px 10px 0 rgba($blue-dark, 0.15) !important; } } } }

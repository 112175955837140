.page-wrapper.maintenance {
  .body-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 730px;
    margin: auto;
    text-align: center;

    h1 {
      line-height: 2rem; }

    img {
      max-height: 400px; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.collapsible-subscription-information {
    .product-name-container {
        align-items: flex-end;

        p, h3 {
            margin-top: 0;
            margin-bottom: 0; } }

    .collapsible-panel-body {
        padding-top: 1rem;

        > *:not(:last-child) {
            margin-bottom: 1rem; } }


    .subscription-information-container {

        p {
            margin-top: 0;
            margin-bottom: 0; }

        > * {
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 1rem; } }

        .label-value-pair {
            width: 100%;
            display: flex;

            .label {
                font-weight: 400;
                flex-grow: 1; }

            .value {
                font-weight: 600; } } }

    .subscription-bonuses-container {
        background-color: $blue-lighter;
        padding: 1rem;
        @include border-radius();

        p {

            margin-top: 0;
            margin-bottom: 0; }

        ul {
            margin: 0;
            margin-top: 0.5rem; } }

    .subscription-ends-container {
        margin-top: 1rem;
        background-color: $blue-lighter;
        @include border-radius();
        @include center-content();
        flex-direction: column;
        padding: 1rem;

        .button {
            min-width: 240px; }
        .feedback-message {
            margin-bottom: 1rem; } } }

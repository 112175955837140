.process-flow {
  .process-flow-step {
    display: flex;
    z-index: 2;
    position: relative;

    .step-indicator-container {
      margin-right: 1rem; }

    .step-indicator {
      width: 25px;
      min-width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      font-size: 1.2rem;
      margin-top: 0.2rem;

      > * {
        width: 25px;
        height: 25px; } }

    &:not(:last-child) {
      margin-bottom: 1rem; } }

  position: relative; }

.user-invite-page {

  .body-content {
    margin: 0 auto;

    > *:not(:last-child) {
      margin-bottom: 2rem; }

    .title-introduction-container {
      text-align: center;

      * {
        margin: 0; }

      > *:not(:last-child) {
        margin-bottom: 1rem; } }

    button {
      max-width: 100%;
      width: 300px; } }

  .user-invite-form-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto; } }



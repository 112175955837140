.confirm-payment-control .checkbox-container .checkbox {
  margin: -0.5rem;
}

.confirm-payment-control p {
  margin: 0;
  font-size: 1rem;
}

.confirm-payment-control form > *:not(:last-child) {
  margin: 0;
  margin-bottom: 2rem;
}

.confirm-payment-control form .button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.confirm-payment-control .loader-container {
  text-align: center;
  padding: 2rem;
}

.confirm-payment-control .feedback-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

.confirm-payment-control .feedback-container > *:not(:last-child) {
  margin-bottom: 1rem;
}

.confirm-payment-control .feedback-container .fa-check {
  font-size: 2rem;
  color: #7cb342;
}

.confirm-payment-control .button-container .button {
  margin-bottom: 1rem;
}

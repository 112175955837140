
.verify-user-holding-page {
  .body-content {
    .main-content {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      max-width: 440px;
      text-align: center;
      h2 {
        margin: 0 0 1.5rem 0; }
      svg {
        transform: scale(1.2); }

      > *:not(:last-child) {
        margin-bottom: 2rem; }

      > div {
        p {
          margin: 0; }

        > *:not(:last-child) {
          margin-bottom: 1rem; } }

      .resend-verification-email-control {
        max-width: 100%;
        width: 300px; } }

    .footer-content {
      text-align: center;

      .payaca-phone-link {
        text-decoration: none;
        font-size: 20px; } } } }

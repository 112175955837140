.record-payment-control .dropdown-field .input-wrapper > * {
  height: 1.5rem;
}

.record-payment-control .checkbox-container .checkbox {
  margin: -0.5rem;
}
.record-payment-control .fields-container > .flex-container > * {
  flex-basis: 50%;
  width: 50%;
}

.record-payment-control .fields-container > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.record-payment-control p {
  margin: 0;
  font-size: 1rem;
}

.record-payment-control form > *:not(:last-child) {
  margin: 0;
  margin-bottom: 2rem;
}

.record-payment-control form .actions-container {
  padding-top: 0 !important;
}

.record-payment-control .loader-container {
  text-align: center;
  padding: 2rem;
}

.record-payment-control .feedback-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

.record-payment-control .feedback-container > *:not(:last-child) {
  margin-bottom: 1rem;
}

.record-payment-control .feedback-container .fa-check {
  font-size: 2rem;
  color: #7cb342;
}

.record-payment-control .label-value-pair .label {
  font-weight: normal;
}

.record-payment-control .label-value-pair .value {
  font-weight: bold;
}

.record-payment-control .warning-message {
  color: red;
  font-size: 0.8em;
  line-height: 1.5em;
}

@import '@payaca/styles/variables.sass';

.job-customer-control {
  .content-panel {
    background-color: $blue-lighter; }

  .read-view {
    position: relative; }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center; }

  .read-view {
    .actions-container {
      position: absolute;
      top: 0;
      right: 0;

      > *:not(:last-child) {
        margin-right: 1rem; } }

    .edit-customer-trigger {
      font-size: 1rem;
      height: 23px;
      cursor: pointer;
      color: rgba($blue-dark, 0.5); }

    .remove-customer-trigger {
      font-size: 1rem;
      height: 23px;
      cursor: pointer;
      color: rgba($blue-dark, 0.5);
      font-size: 1.25rem; } }

  .basic-create-edit-customer-control .actions-container {
    display: flex;
    justify-content: flex-end; }

  .input-view {
    .autocomplete-field-container > * {
      margin-bottom: 0; }

    > .flex-container {
      align-items: flex-end;

      .button {
        padding-top: 1rem;
        padding-bottom: 1rem; } }

    .lookup-customer-button-container {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end; } }

  .customer-read-view-container {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 0.35rem; }

  .create-or-add-existing-container {
    margin-left: 1rem;
    margin-right: 1rem; }

  .customer-contact-select-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    .radio-button {
      margin-right: 1rem; }

    .customer-contact {
      margin-bottom: 0; } } }



@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';


.current-subscription-controls {
    .current-subscription-controls-inner {

        > *:not(:last-child) {
            margin-right: 2rem; } }

    .subscription-information-content-panel {
        padding: 2rem;
        > *:not(:last-child) {
            margin: 0;
            margin-bottom: 2rem; } }

    .rhs {
        flex-basis: 366px;

        > *:not(:last-child) {
            margin-bottom: 2rem; }

        .update-payment-method-trigger-container {
            @include center-content(); }

        .zero-percent-container {
            @include border-radius();
            background-color: $blue-lighter;
            padding: 1.5rem;
            text-align: center;

            h4 {
                font-size: 1.2rem;
                margin: 0;
                line-height: 1.5em; } } }

    .cancel-subscription-information {
        text-align: center;
        margin-top: 1rem; }

    &.sm-view {
        .current-subscription-controls-inner {
            flex-direction: column;
            > * {
                margin: 0;
                &:not(:last-child) {
                    margin-bottom: 2rem; } } }

        .payment-method-card {
            margin-left: auto;
            margin-right: auto; } } }

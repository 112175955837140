@import '@payaca/styles/variables';

.profile-navigation {
    .navigation-menu-item-wrapper {
        border-bottom: 1px solid $blue-light; }

    .navigation-menu-item {
        padding: 1.2rem 0.5rem;


        &.selected {
            .icon-container {
                background-color: $blue-lighter; } }

        &:hover:not(.disabled) {
            background-color: $blue-lighter; } } }

.new-edit-customer-page {

  .body-content {
    padding: 0 2rem 2rem 2rem; }

  .form-section:first-child .form-section-content {
    margin-bottom: 0px; }


  .create-edit-customer-control {
    max-width: 1000px;
    margin: 0 auto; } }

@media (min-width: 700px) {
  .new-edit-customer-page {

    .body-content {
      padding: 0 4rem 4rem 4rem; } } }

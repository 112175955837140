.grant-access {
  display: flex;
  flex-direction: column;
  max-width: 28rem;
  padding: 1rem;
  margin: auto;
  height: 100%;
  align-items: center;
}

.grant-access-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.grant-access img.logo {
  height: 4rem;
  width: auto;
}

.grant-access .button.tertiary {
  margin-top: 0;
}

.grant-access .app-scopes-wrapper {
  width: 100%;
}

.grant-access .app-scopes {
  padding: 1rem;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  font-size: 13px;
}

.grant-access .app-scope {
  padding: 0.6rem 0.3rem;
  border-bottom: 1px solid #222;
}
.grant-access .app-scope:last-child {
  border-bottom: none;
}

.grant-access .logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grant-access .scope.icon {
  margin-right: 10px;
  font-size: 16px;
}

.grant-access .exchange.icon {
  margin: 20px;
  font-size: 30px;
  color: #222;
}

.grant-access h4 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: left;
}
.grant-access-footer {
  cursor: pointer;
  text-decoration: underline;
}
.grant-access-footer .fa-chevron-right {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.grant-access .button {
  margin-top: 10px;
}

.grant-access h3 {
  margin: 0.6rem 0em;
}

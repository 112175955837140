
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

$control-width: 200px;

.responsive-popper-and-trigger {

    width: auto;

    .show-popper-trigger {
        display: inline-block;
        max-width: 100%;
        cursor: pointer;
        @include center-content(); } }

.responsive-popper-and-trigger-popper {
    margin-top: 0.75rem;
    z-index: 100;

    .MuiPaper-root {
        padding: 1rem 0;
        @include border-radius();
        @include box-shadow(); } }

.responsive-popper-and-trigger-drawer {

    .MuiPaper-root {
        border-radius: 10px 10px 0 0;
        @include box-shadow(); } }

.change-password-form-container {
    form {
        > *:not(:last-child) {
            margin-bottom: 2rem; }

        .new-password-input-container {
            > *:not(:last-child) {
                margin-bottom: 1rem; } }


        .password-validation-feedback {
            padding-top: 0.5rem; } } }

.create-edit-line-item-control form > *:not(:last-child) {
  margin-bottom: 3rem;
}

.create-edit-line-item-control .form-section > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.create-edit-line-item-control form hr {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  border-bottom: 1px solid #c4c4c4;
}

.create-edit-line-item-control form .inline-field-container {
  flex: 1 1 100%;
}

.create-edit-line-item-control .button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-edit-line-item-control .button-container .button {
  min-width: 240px;
}

.create-edit-line-item-control .job-attachments {
  padding: 0;
}

.create-edit-line-item-control p.error-message {
  margin-top: 0;
  margin-bottom: 1rem;
  color: red;
  text-align: center;
}

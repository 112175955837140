.get-started-form-section .company-registration-number-field .basic-field {
  flex-grow: 1;
}

.get-started-form-section .company-registration-number-field .button {
  margin: 0;
}

.get-started-form-section .company-registration-number-field {
  align-items: flex-end;
}

.get-started-form-section
  .companies-house-company-preview-container
  .companies-house-company-preview-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
}

.get-started-form-section
  .companies-house-company-preview-container
  .companies-house-company-preview-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
}

.get-started-form-section
  .companies-house-company-preview-container
  .companies-house-company-preview-wrapper
  > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.get-started-form-section
  .companies-house-company-preview-container
  .loader-container {
  text-align: center;
}

.get-started-form-section .company-preview-action-warning {
  font-size: 0.9em;
  color: red;
}

.get-started-form-section .clickable {
  font-weight: bold;
  cursor: pointer;
}


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.deal-page {
    .header-content {
        .title-bar {
            padding-bottom: 0;
            padding-top: 2rem;

            .title-bar-inner {
                padding-bottom: 1rem;
                border-bottom: 1px solid $blue-light;

                .back-button-container {
                    margin-bottom: 2rem; } } } }



    .page-wrapper-inner .page-content > .body-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
        overflow-y: hidden;

        .tabbed-view-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow-y: hidden;


            .tabs-wrapper {
                padding-left: 4rem;
                padding-right: 2rem;
                margin: 0;
                height: 65px;
                flex-basis: 65px;
                flex-shrink: 0;

                .tabs {
                    height: 100%;
                    align-items: center; } }

            .tab-content-container {
                flex-grow: 1;
                overflow-y: scroll;
                padding-left: 4rem;
                padding-right: 2rem;
                padding-top: 0.5rem;
                padding-bottom: 2rem; } }

        .additional-tab-header-content {
            z-index: 1;
            position: absolute;
            right: 0;
            height: calc(65px + 1rem);
            margin: -1rem 2rem 0 0; }

        .additional-tab-header-content-wrapper.sm-view {
            .additional-tab-header-content {
                position: relative;
                margin: 0 2rem 0 4rem;
                height: auto;
                justify-content: right; } } } }




@media (max-height: 800px) {
    .deal-page {

        .page-wrapper-inner .page-content > .body-content {
            overflow-y: scroll;

            .tabbed-view-container {
                overflow-y: visible;
                display: block;


                .tab-content-container {
                    overflow-y: visible; } } } } }



@import '@payaca/styles/mixins';
@import '@payaca/styles/variables';
.update-subscription-control {
  .form-section:not(:last-child) {
    margin-bottom: 2rem; }

  .form-section-header {
    margin: 1rem 0;
    text-align: center;
    font-size: 1.2rem; }

  .loader-container {
    display: flex;
    justify-content: center; }

  .review-update-payment-method-container {
    text-align: center;
    margin: 0 3rem;
    padding: 1rem;
    border-top: 1px solid rgba($blue-darker, 0.2);

    strong {
      white-space: pre; }

    .update-payment-method-container {
      margin: 0 auto;
      max-width: 600px; }

    .review-payment-method-container {
      > p > span {
        display: block;
        padding: 0.5rem 0 0; } } }

  .actions-container {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      width: 250px;
      &:not(:last-child) {
        margin-right: 1rem; } }

    .button {
      padding-top: 1rem;
      padding-bottom: 1rem; } }


  .failed-update-error-message {
    color: red;
    text-align: center; } }

.dev-page .body-content {
  padding-left: 4rem;
  padding-right: 2rem;

  section {
    max-width: 450px;
    margin: 0 auto;

    > *:not(:last-child) {
      margin-bottom: 1rem; } } }


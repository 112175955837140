
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-timeline-event {
  background-color: $blue-lighter;
  @include border-radius();
  padding: 0.5rem 1rem;
  position: relative;
  overflow: hidden;
  border-left: 1rem solid;

  .timestamp {
    white-space: pre; }

  .event-information-container {
    flex-grow: 1;
    margin-right: 4rem;

    > * {
      display: block; } }

  &.proposition-deposit-paid,
  &.proposition-accepted,
  &.proposition-satisfaction-note-signed,
  &.invoice-paid, {
    background-color: $green;
    border-color: $green; }

  &.invoice-sent,
  &.proposition-sent,
  &.proposition-satisfaction-note-sent,
  &.proposition-satisfaction-note-reminder-sent {}
  &.invoice-raised, {
    border-color: $blue-darker; }

  &.proposition-viewed,
  &.invoice-viewed,
  &.finance-link-clicked,
  &.proposition-finance-application-started,
  &.proposition-customer-action-requested {
    border-color: $yellow-dark; }

  &.proposition-expired {
    border-color: $red; }

  &.proposition-declined,
  &.proposition-finance-application-rejected,
  &.proposition-satisfaction-note-rejected {}
  &.invoice-overdue {
    background-color: $red;
    border-color: $red; } }

.deal-header {
  align-items: stretch;

  .deal-information-control-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2rem;

    > .button-container {
      margin-bottom: 1rem;
      flex-grow: 1; } }

  &.sm-view {
    flex-direction: column;
    align-items: stretch;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem; }

    .deal-customer-control {
      align-self: center; } } }



.four-oh-four-page {

    .information-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column; }


    .sad-face {
        max-width: 300px;
        flex-shrink: 1;
        margin-bottom: 3rem;

        img {
            max-width: 100%; } }

    .information-container {
        text-align: center;

        h1 {
            font-size: 10rem;
            line-height: 1em;
            margin: 0; }

        h2 {
            font-size: 2rem;
            line-height: 1em;
            margin: 0; }

        button {
            margin: 0 auto;
            margin-top: 2rem; } } }

@media (min-width: 700px) {

    .four-oh-four-page {

        .information-wrapper {
            flex-direction: row; }


        .sad-face {
            max-width: 440px;
            margin: 0;
            margin-right: 3rem; } } }

.cost-summary {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  border-bottom: 1px solid #000;
  padding: 0.6rem;
}

.cost-summary label {
  font-family: Poppins;
  font-weight: 600;
  padding-right: 0.5rem;
}

@import '@payaca/styles/variables';

.update-subscription-additional-user-seats-control {
  .form-section-header {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem; }

  .actions-container {
    display: flex;
    align-items: center;
    justify-content: center; }

  .review-payment-method-container {
    text-align: center;
    margin: 0 3rem;
    padding: 1rem;
    border-top: 1px solid rgba($blue-darker, 0.2); } }

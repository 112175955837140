.key-indicator-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.key-indicator-data .title-container {
  margin-bottom: 1rem !important;
  text-align: center;
}

.key-indicator-data .title-container .microcopy {
  line-height: 1em;
  display: block;
}

.key-indicator-data .title-container h3 {
  margin: 0;
}

.key-indicator-data .current-period-value {
  font-size: 2rem;
  line-height: 1em;
  font-weight: 600;
}

.key-indicator-data .previous-period-value-container {
  font-size: 0.9em;
  display: flex;
  color: #888;
}

.key-indicator-data .previous-period-value-container > *:not(:last-child) {
  margin-right: 0.5rem;
}

.key-indicator-data .percentage-change-element {
  font-weight: 600;
}

.key-indicator-data .percentage-change-element svg {
  margin-right: 0.25rem;
}

.key-indicator-data .percentage-change-element.increase {
  color: #8bc34a;
}

.key-indicator-data .percentage-change-element.decrease {
  color: red;
}

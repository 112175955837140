.customer-information p {
  margin: 0;
}

.customer-information hr {
  margin: 0.5rem 0;
}

.customer-information .customer-name,
.customer-information .contact-name {
  font-weight: bold;
}

.customer-information .contact-name .fa-check {
  margin-left: 0.5rem;
  color: #8bc34a;
}

.customer-information .customer-contacts-inner {
  margin-bottom: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.customer-information .customer-contacts .customer-contact {
  min-width: 300px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.customer-information .fa-envelope,
.customer-information .fa-phone,
.customer-information .fa-map-marker-alt {
  min-width: 1rem;
  margin-right: 0.5rem;
}

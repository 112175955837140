@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.subscription-product-selection-control-outer {
  position: relative;
  padding-top: 1.6rem;

  .badge {
    position: absolute;
    top: 0;
    width: 100%;
    height: 4rem;
    @include border-radius();
    text-align: center;
    text-transform: none;
    letter-spacing: 0; } }

.subscription-product-selection-control {
  background-color: $blue-lighter;
  @include border-radius();
  max-width: 250px;
  position: relative;
  padding-bottom: 4rem;
  overflow: hidden;
  text-align: center;
  height: 100%;

  .subscription-product-selection-control-inner {
    padding: 1rem; }

  p {
    margin: 0; }

  &.selected {
    background-color: $blue-light; }

  .subscription-product-selection-control-inner > *:not(:last-child) {
    margin: 0;
    margin-bottom: 1rem; }

  .product-name-container {
    text-align: center;
    margin-bottom: 1.5rem; }

  .product-name {
    font-size: 1.2rem;
    margin: 0; }

  .button-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 1rem;

    .button {
      width: 100%; } }


  .additional-user-seats-container {
    h3 {
      font-size: 1rem;
      text-align: center;
      margin: 0; }

    > *:not(:last-child) {
      margin-bottom: 0.5rem; }

    .additional-user-seat-cost {
      font-size: 0.9em; } }

  .cost-container {
    font-size: 0.9em;
    line-height: 1.5em;
    text-align: center;

    .cost {
      font-size: 2rem;
      font-weight: bold; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.finance-provision-option-overview {
  @include border-radius();
  padding: 0.5rem;
  background-color: $white;
  border: 1px solid $blue-light;

  &.active {
    background-color: $blue-lighter;
    border-color: $blue-lighter; }

  hr {
    border-color: $blue-light; }

  .title {
    text-align: center; }

  .finance-provision-option-overview-details {
    > * {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 1rem; } } }

  .process-overview-container {
    padding-left: 0.25rem;

    .process-flow {
      .step-indicator-container {
        color: $yellow-dark; } } }

  h4 {
    font-size: 1rem;
    margin: 0; }


  .requirements-container, .benefits-container {
    h4 {
      margin-bottom: 1rem; }

    .requirement, .benefit {
      line-height: 1.4em;

      &:not(:last-child) {}
      margin-bottom: 0.3rem; } }

  .costs-container {
    .badge {
      text-align: center; } }

  .icon-container {
    color: #fab62d;
    height: 1.4em;
    min-width: 1em;
    margin-left: 0.25rem;
    text-align: right;
    display: inline-block; }

  .call-to-action-container button {
    width: 100%;
    max-width: 100%; }

  // .process-flow .step-indicator-container
  //   padding-top: 0.5rem
  //   padding-bottom: 0.5rem

  // .finance-provision-option-overview-details
  //   padding: 1rem
  //   border: 1px solid #c4c4c4
  //   min-height: 614.31px

  // hr
  //   border-color: #c4c4c4

  // &.active
  //   .finance-provision-option-overview-details
  //     border: 1px solid #8bc34a
  //     background-color: #f8fbf4

  //   hr
  //     border-color: #8bc34a

  // .finance-provision-option-overview-details > *:not(:last-child)
  //   margin-bottom: 1rem

  // h4
  //   font-size: 1rem
  //   margin: 0

  // .requirements-container h4, .benefits-container h4
  //   margin-bottom: 1rem

  // .button
  //   margin: 0
  //   width: 100%

  // .requirement, .benefit
  //   line-height: 1.4em

  //   &:not(:last-child)
  //     margin-bottom: 0.3rem

  // .icon-container
  //   color: #fab62d
  //   height: 1.4em
  //   min-width: 1em
  //   text-align: center
  //   display: inline-block



  // .costs-container > *:not(:last-child)
 }  //   margin-bottom: 1rem

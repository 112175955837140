.register-page {
  .body-content {
    padding: 1rem 0; }

  .login-prompt {
    button {
      display: inline; } }

  .initial-register-control {
      width: 100%; }

  .register-form-container {
    width: 100%;
    max-width: 300px; } }

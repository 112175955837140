@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.authenticated-page-header {
    padding: 0 1.5rem;
    background-color: $blue-lighter;
    display: flex;
    align-items: flex-center;
    min-height: 70px;

    > *:not(:last-child) {
        margin-right: 1rem; }

    .date-container {
        font-weight: 600;
        @include center-content();
        white-space: nowrap;

        svg {
            color: $yellow-dark;
            margin-right: 0.5rem; } }



    .info-actions-container,
    &:not(.sm-view) .header-search-control {
        flex-grow: 1;
        flex-basis: 50%; }

    .info-actions-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > *:not(:last-child) {
            margin-right: 1rem; }

        .header-search-control-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1; } }

    .show-navigation-sidebar-toggle {
        cursor: pointer;
        font-size: 1.25rem;
        @include center-content(); } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.section-radio-buttons {
    .radio-button-wrapper {
        height: 50px;
        width: 100%;
        flexDirection: row;
        display: flex;
        overflow: hidden;
        border: 1px solid $blue-dark;
        background-color: $white;
        color: $blue-dark;
        @include border-radius();

        .section-radio-button {
            display: flex;
            flexDirection: row;
            alignItems: center;
            cursor: pointer;
            flex-grow: 1;
            @include center-content();
            transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;

            &:hover {
                background-color: rgba($blue-dark, 0.1); }

            &.selected {
                background-color: $blue-dark;
                color: $white; }

            &:not(:last-child) {
                border-right: 1px solid $blue-dark; }

            .select-button-wrapper {

                .select-button {} } } } }


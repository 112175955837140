.listed-deals-filter-controls {
  .filters-wrapper {
    .filter-by-label {
      padding: 6px 0; }


    .input-wrapper {
      width: 190px; }

    & > svg {
      width: 0.65rem; } }

  .filters-wrapper {
    .MuiSelect-icon {
      display: none; }


    .clear-filters .icon-button-container:hover + .clear-filters-tooltip {
      display: block; }

    .clear-filters-tooltip {
      display: none;
      position: absolute;
      background-color: #edf2f7;
      width: 85px;
      padding: 5px;
      z-index: 1000000;
      bottom: -40px;
      left: -30px;
      text-align: center;
      font-size: 12px;
      border-radius: 5px;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #edf2f7;
        top: -5px;
        left: 40px;
        z-index: 5; }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #edf2f7;
        top: -6px;
        left: 40px; } } } }

#menu-userAssignments .MuiPaper-root,
#menu-readableStatuses .MuiPaper-root,
#menu-types .MuiPaper-root {
  width: 190px;
  margin-top: 20px;
  margin-left: -15px;
  max-height: 315px;
  border-radius: 10px; }

#menu-userAssignments .MuiPaper-root li,
#menu-readableStatuses .MuiPaper-root li,
#menu-types .MuiPaper-root li {
  padding-top: 10px;
  padding-bottom: 10px; }

#menu-userAssignments .MuiPaper-root .checkbox,
#menu-readableStatuses .MuiPaper-root .checkbox,
#menu-types .MuiPaper-root .checkbox {
  margin-left: -10px; }



.register-form-container {
    min-height: 590px;

    form {
        .password-validation-feedback {
            padding-top: 0.5rem; }

        > *:not(:last-child) {
            margin-bottom: 1rem; }

        button.button-style-outsize {
            width: 100%;

            & + .feedback-level-error {
                margin-top: 0.5rem; } }

        .referral-token-control .collapsible-panel {
            margin-left: -1rem;
            margin-right: -1rem;

            .collapsible-panel-body {
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 1rem; }

            &.open {
                .collapsible-panel-header {
                    cursor: default; } }


            .collapsible-panel-header {
                padding-left: 1rem;
                padding-right: 1rem;

                button {
                    display: inline; }

                .control-icon-container {
                    display: none; }

                .enter-referral-code-prompt {
                    text-align: center; } } }
        .marketing-and-terms {
            font-size: 0.8rem;
            .checkbox-field {
                align-items: flex-start;
                .checkbox-wrapper {
                    margin-right: 0.8rem; } }
            p {
                margin: 0; } } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.listed-users-table {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;

    .pagination-control {
      padding: 1rem 0rem 1rem 3rem; }

    .no-results-container, .loader-container {
        @include center-content(); }

    .styled-table-wrapper {
        border-radius: 0;
        border: none;
        flex-grow: 1; }


    table.styled-table {



        td, th {
            padding: 0.75em;
            vertical-align: middle; }

        th {

            padding-top: 0;

            .row-selection-table-cell {
                width: 3rem;
                max-width: 3rem;
                max-height: 3rem; } }

        .is-primary-user-table-cell {
            font-size: 1.5rem;

            .fa-check-circle {
                color: $blue-darker; }

            .fa-times-circle {
                color: rgba($red, 0.5); } }

        .status-table-cell {
            .badge {
                text-transform: uppercase;
                font-size: 0.8rem;
                letter-spacing: 0.2em;
                padding: 0.5em 1em; } } } }





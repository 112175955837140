.client-information-flyer-content-panel .client-information-flyer-image {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  max-width: 100%;
}

.client-information-flyer-content-panel
  .client-information-flyer-image-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.client-information-flyer-content-panel .button {
  margin: 0;
}

.client-information-flyer-content-panel h4 {
  margin: 0;
}

.client-information-flyer-content-panel > *:not(:last-child) {
  margin-bottom: 1rem;
}

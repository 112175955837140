@import '@payaca/styles/variables.sass';

.job-line-item-groups-control {
  .content-panel {
    margin-bottom: 2rem; }

  > .create-or-add-existing-container {
    margin-left: 1rem;
    margin-right: 1rem; }

  .job-line-item-group-controls-container {
    > .content-panel {
      border: 1px solid rgba($blue-darker, 0.2) !important;
 } } }      //background-color: rgba($blue-lighter, 0.5)

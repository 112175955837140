
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';


.job-options {
  .checkbox-field {
    margin-left: 9px; }

  .collapsible-panel.collapsible-panel-style-minimal {
    .collapsible-panel-header {
      background-color: $blue-light;
      color: $blue-darker; }

    .collapsible-panel-body {
      padding-top: 0.5rem; } }

  &.open {
    .collapsible-panel-header {
      background-color: $blue-light;
      color: $blue-darker; } } }

.select-subscription-control {
  .form-section {
    margin-bottom: 2rem;

    &:not(:last-child) {
      padding-bottom: 2rem;
      border-bottom: 1px solid #c4c4c4; } }

  .form-section-header {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.2rem; }

  .loader-container {
    display: flex;
    justify-content: center; }

  .review-payment-method-container {
    text-align: center; }

  .actions-container {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      width: 250px; }

    .button {
      padding-top: 1rem;
      padding-bottom: 1rem; }

    > *:not(:last-child) {
      margin-right: 1rem; } }

  .update-payment-method-container {
    margin: 0 auto;
    max-width: 600px; }

  .failed-update-error-message {
    color: red;
    text-align: center; } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.checkbox .MuiCheckbox-root {
  color: $blue-darker;

  .checkmark-wrapper {
    border: 2px solid $blue-darker;
    height: 16px;
    width: 16px;
    @include border-radius-sm();
    @include center-content();
    font-size: 10px;
    color: $blue-darker; } }


.subsidised-finance-information-form-page
  .subsidised-finance-information-form-page-content {
  padding: 2rem;
}

.subsidised-finance-information-form-page
  .subsidised-finance-information-form-page-content
  > *:not(:last-child) {
  margin-bottom: 2rem;
}

.subsidised-finance-information-form-page form .field-group {
  margin-bottom: 2.5rem;
}

.subsidised-finance-information-form-page form .checkbox-container .checkbox {
  margin: -0.5rem;
}
.subsidised-finance-information-form-page
  form
  .field-group
  > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.subsidised-finance-information-form-page form .validated-field-wrapper {
  width: 100%;
}

.subsidised-finance-information-form-page .action-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.subsidised-finance-information-form-page .action-buttons-container .button {
  margin: 0;
  min-width: 150px;
}

.subsidised-finance-information-form-page
  .action-buttons-container
  .loader-container {
  text-align: center;
}

.subsidised-finance-information-form-page
  .action-buttons-container
  > *:not(:last-child) {
  margin-right: 1rem;
}

.subsidised-finance-information-form-page form .validation-error {
  color: red;
  margin-top: 0.2rem;
}

.subsidised-finance-information-form-page-content > .content-panel {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.categories-key .key-items-container {
  flex-wrap: wrap;
}

.categories-key {
  font-size: 0.8rem;
  line-height: 1.2em;
}

.categories-key .key-item-colour {
  margin-right: 0.3rem !important;
  width: 2rem;
  height: 1rem;
  background-color: black;
  margin-top: 1px;
  margin-bottom: 1px;
}

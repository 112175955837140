@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.subscription-products-selection-control {
  .products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -1rem;
    margin-bottom: -1rem;
    align-items: stretch;

    > * {
      margin-left: 1rem;
      margin-bottom: 1rem; }

    .button.button-col-grey.disabled {
      background-color: $blue-light; } } }


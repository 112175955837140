@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.authenticated-page-wrapper {
    .header-content {
        .title-bar {
            display: flex;
            min-height: 70px;
            align-items: center;
            padding: 1rem 2rem 1rem 4rem;

            h1 {
                padding: 0;
                margin: 0;
                font-size: 20px; } } }


    .page-wrapper-inner {
        height: 100vh;
        max-width: 100%;
        display: flex;
        flex-direction: row;

        .navigation-sidebar-container {
            height: 100%; }

        .page-content {
            height: 100%;
            max-height: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .header-content {}

            > .body-content {
                flex-grow: 1;
                overflow-x: hidden;
                overflow-y: scroll; } } }

    &.sm-view {
        .page-wrapper-inner {
            position: relative;

            .navigation-sidebar-container {
                position: absolute;
                z-index: 100;
                width: 100%;
                background-color: rgba($blue-darker, 0.0);
                transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
                pointer-events: none;

                .navigation-sidebar .collapsible-sidebar {
                    box-shadow: 10px 0 10px 0px rgba($blue-darker, 0.15); }

                &.sidebar-visible {
                    background-color: rgba($blue-darker, 0.3);
                    pointer-events: all;
                    backdrop-filter: blur(0.5px); } } } } }

.analytics-dashboard-unsubscribed-holding-content {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.analytics-dashboard-unsubscribed-holding-content h4 {
  font-size: 2.5rem;
  opacity: 0.5;
  line-height: 1em;
}

.analytics-dashboard-unsubscribed-holding-content .content-panel {
  max-width: 800px;
  margin: 0.5rem;
  text-align: center;
  padding: 2rem 3rem;
}

.analytics-dashboard-unsubscribed-holding-content .content-panel .button {
  margin: 0 auto;
}

.analytics-dashboard-unsubscribed-holding-content .content-panel img {
  max-width: 100%;
}

.analytics-dashboard-unsubscribed-holding-content
  .content-panel
  > *:not(:last-child) {
  margin: 0;
  margin-bottom: 1rem;
}

.analytics-dashboard-unsubscribed-holding-content .screenshot-overlay span {
}

@media (max-width: 600px) {
  .analytics-dashboard-unsubscribed-holding-content {
    padding: 0.5rem;
  }

  .analytics-dashboard-unsubscribed-holding-content .content-panel {
    padding: 1rem;
  }
}

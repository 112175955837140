.key-indicators-data .key-indicator-data-container {
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -2rem;
}

.key-indicators-data .key-indicator-data-container .key-indicator-data {
  min-width: 280px;
  margin-bottom: 2rem;
}

.key-indicators-data p {
  margin: 0;
}

.key-indicators-data > *:not(:last-child) {
  margin: 0;
  margin-bottom: 2rem;
}

.key-indicators-data .microcopy-container {
  text-align: center;
}

/* .key-indicators-data h2 {
  text-align: center;
  font-size: 1rem;

  font-weight: 400;
} */

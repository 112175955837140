
@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.header-information-resources-control {
    .responsive-popper-and-trigger {
        .show-popper-trigger {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include center-content();
            transition: box-shadow 0.1s ease-in-out;

            img {
                width: 22px;
                height: 22px; } }

        &.active {
            .show-popper-trigger {
                @include box-shadow(); } } } }

.header-information-resources-control-popper {
    width: 180px; }

.header-information-resources-control-drawer {

    .MuiPaper-root {

        .header-information-resources-navigation {
            padding: 0.5rem 0; } } }


.analytics-dashboard-content {
  padding: 1rem 2rem 4rem 4rem;
}

.analytics-dashboard-content-inner {
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;
}

.analytics-dashboard-content .content-panel {
  flex-basis: calc(50% - 2rem);
  margin: 1rem;
}

.analytics-dashboard-content .content-panel.full-width {
  flex-basis: calc(100% - 1rem);
}

.analytics-dashboard-content .content-panel > *:not(:last-child) {
  margin-bottom: 1rem;
}

.analytics-dashboard-content .content-panel h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.analytics-dashboard-content .content-panel .microcopy {
  font-size: 0.7rem;
  color: #888;
}

.analytics-dashboard-content .subscription-usage-data {
  width: 100%;
  justify-content: center;
}

.analytics-dashboard-content .tooltip-icon {
  padding: 0;
}

@media (max-width: 1000px) {
  .analytics-dashboard-content .content-panel {
    flex-basis: 100%;
  }
}

@media (max-width: 600px) {
  .analytics-dashboard-content {
    padding: 0.5rem;
  }
}

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.finance-options-page {
  .header-content {
    .title-bar {
      padding-bottom: 0;
      display: flex;
      align-items: stretch;

      .title-bar-inner {
        border-bottom: 1px solid $blue-light;
        width: 100%;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;

        .button-container {
          width: 100px; }

        h1 {
          flex-grow: 1;
          margin-right: 100px;
          text-align: center; } } } }

  .body-content {
    padding: 1rem 2rem 2rem 4rem;

    > * {
      margin: 0;

      margin-bottom: 2rem; } }

  .how-finance-works-page-description {
    text-align: center; }

  .contact-us-callout {
    margin: 0 auto; }

  .finance-options-container {
    align-items: flex-start;

    > * {
      flex-basis: 0;
      flex-grow: 1; }

    &.sm-view {
      flex-direction: column;

      > * {
        width: 100%;

        button {
          max-width: 240px;
          margin: 0 auto; }

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 2rem; } } } }



 }  // /* .how-finance-works-page {
//   background-color: #ededea;
// }

// .how-finance-works-page .how-finance-works-page-description {
//   padding: 2rem 2rem 0em;
// }
// .how-finance-works-page .how-finance-works-page-content {
//   padding: 2rem;
// }

// .how-finance-works-page .additional-cost-information {
//   font-size: 0.9rem;
// }

// .how-finance-works-page .additional-cost-information > *:not(:last-child) {
//   margin-bottom: 0.5rem;
// }

// .how-finance-works-page .content-panel > .flex-container {
//   justify-content: center;
//   padding: 3rem;
// }

// .how-finance-works-page .finance-provision-option-overview {
//   max-width: 320px;
//   flex-grow: 1;
//   flex-basis: 320px;
// }

// .how-finance-works-page
//   .finance-provision-option-overview
//   .benefits-container
//   a {
//   text-decoration: underline;
// }

// .how-finance-works-page .contact-us-callout {
//   margin: 0 auto;
//   margin-top: 2rem;
// }

// @media (max-width: 1400px) {
//   .how-finance-works-page .content-panel > .flex-container {
//     flex-wrap: wrap;
//   }
//   .how-finance-works-page .finance-provision-option-overview {
//     min-width: 45%;
//   }
//   .how-finance-works-page .finance-provision-option-overview:nth-of-type(1),
//   .how-finance-works-page .finance-provision-option-overview:nth-of-type(2) {
//     margin-bottom: 1rem;
//   }
// }

// @media (max-width: 1200px) {
//   .how-finance-works-page .content-panel > .flex-container {
//     flex-direction: column;
//     align-items: center;
//   }

//   .how-finance-works-page
//     .content-panel
//     > .flex-container
//     > *:not(:last-child) {
//     margin: 0;
//     margin-bottom: 2rem;
//   }

//   .how-finance-works-page .finance-provision-option-overview {
//     min-width: 100%;
//     flex-grow: 1;
//     flex-basis: 320px;
//   }
// }

// @media (max-width: 600px) {
//   .how-finance-works-page .content-panel > .flex-container {
//     padding: 1rem;
//   }
// }

// @media (min-width: 1404px) {
//   .how-finance-works-page
//     .finance-provision-option-overview
//     .requirements-container {
//     min-height: 260px;
//   }
//   .how-finance-works-page
//     .finance-provision-option-overview
//     .benefits-container {
//     min-height: 260px;
//   }
// } */

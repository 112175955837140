@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-preview-update-reminder {
  background: $blue-light;
  @include border-radius();
  padding: 1rem;

  .button {
    width: 100%;
    margin-top: 1rem; }

  p {
    margin-top: 0rem; } }



@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.loading-container {
    @include center-content();


 }    // .deal-timeline-line-container .job-timeline-event {
//     padding: 1.5em;
//     font-size: 12px;
// }

// .deal-timeline-line-container .job-timeline-event .timestamp {
//     font-size: 12px;
// }

.cta-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 1rem; }

  .button {
    min-width: 420px; } }

@import '@payaca/styles/variables';

.financial-information-form-section {
  .field-group > .content-panel {
    background: $blue-lighter;
    .content-panel {
      background: $blue-light; } } }

/* SMALL VIEW STYLES */

.financial-information-form-section.sm-view .field-group > .flex-container:not(.checkbox-container) {
  flex-direction: column; }

.financial-information-form-section.sm-view .field-group > .flex-container:not(.checkbox-container) > *:not(:last-child) {
  margin-bottom: 0.5rem; }

.financial-information-form-section.sm-view .product-form-subsection .content > .flex-container:not(.checkbox-container) {
  flex-direction: column; }

.financial-information-form-section.sm-view .product-form-subsection .content > .flex-container:not(.checkbox-container) > *:not(:last-child) {
  margin-bottom: 1rem; }

/* END SMALL VIEW STYLES */

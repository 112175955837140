@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.edit-job-page {

  .header-content {
    .title-bar {
      min-height: auto;
      padding: 2rem 2rem 1rem 2rem; } }
  .archived-banner {
    padding: 0.5rem 1rem;
    background-color: $red;
    text-align: center;
    color: $white; }

  .page-wrapper-inner {

    .page-content {

      > .body-content {
        padding: 1rem 2rem 2rem 2rem; } } }

  .preview-and-send-job-control {
    .lhs {
        background-color: $blue-lighter;
        @include border-radius();

        .title-bar {
            padding: 2rem; }

        .preview-content {
            padding: 2rem;
            padding-top: 1rem;

            .content-panel {
                border-radius: 0; } } }

    .sidebar-content {

        > *:not(:last-child) {
            margin-bottom: 2rem; }

        .marked-as-sent-explainer-microcopy {
            display: block;
            text-align: center; } } } }

.preview-and-send-job-modal.modal {
  .MuiDialog-paperScrollPaper {
    height: 90vh; }

  .preview-content {
    padding-bottom: 2rem; }


  .preview-content-outer {
    margin: 0 -0.5rem;
    padding: 0 0.5rem;


    &:before {
        content: '';
        position: absolute;
        z-index: 2;
        box-shadow: 0px 10px 10px -10px rgba($blue-dark, 0.5) inset;
        left: 0;
        right: 0;
        top: 0;
        height: 20px; }

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        box-shadow: 0px -10px 10px -10px rgba($blue-dark, 0.5) inset;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20px; } } }


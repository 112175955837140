.create-edit-customer-control .actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-edit-customer-control .actions-container .button {
  width: 100%;
  max-width: 250px;
}

.create-edit-customer-control .control-type-actions-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
}

.initial-register-control {
    min-height: 500px;

    > *:not(:last-child) {
        margin-bottom: 2rem; }

    .register-with-email-prompt {
        hr {
            flex-grow: 1; } }

    > *:not(.register-with-email-prompt) {
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto; }

    .social-register-buttons-container {

        > *:not(:last-child) {
            margin-bottom: 1rem; } } }

.add-user-modal p {
  margin: 0;
}

.add-user-modal > *:not(:last-child) {
  margin-bottom: 1rem;
}

.add-user-modal .user-seats-filled > *:not(:last-child) {
  margin-bottom: 1rem;
}

.add-user-modal .user-seats-filled .contact-us-directly-trigger {
  cursor: pointer;
}

.progress-bar .progress-bar-segment {
  display: inline-block;
  margin: 20px;
  border-bottom: 2px solid transparent;
  opacity: 0.4;
}

.progress-bar-segment.active {
  border-bottom: 2px solid #fab62d;
  opacity: 1;
}

.progress-bar-segment.clickable {
  cursor: pointer;
  opacity: 1;
}

.progress-bar-segment.clickable label {
  cursor: pointer;
}

@media (max-width: 700px) {
  .progress-bar .progress-bar-segment {
    margin: 20px 10px;
  }
}

.mark-as-complete-modal .button-container {
  display: flex;
  justify-content: flex-end !important;
}

.mark-as-complete-modal .button-container .button {
  margin: 0;
}

.mark-as-complete-modal {
  padding-bottom: 1rem !important;
}

.sent-quote-value-chart .quote-pending-value {
  fill: #fabb00;
  background-color: #fabb00;
}

.sent-quote-value-chart .quote-accepted-value {
  background-color: #4ea83e;
  fill: #4ea83e;
}

.sent-quote-value-chart .quote-declined-value {
  background-color: red;
  fill: red;
}

.sent-quote-value-chart .internal-bar-label {
  fill: white;
}

.sent-quote-value-chart .quote-pending-value-bar-label.internal-bar-label,
.sent-quote-value-chart .quote-pending-value-bar-label.external-bar-label {
  fill: #263e59;
}
.sent-quote-value-chart .quote-accepted-value-bar-label.external-bar-label {
  fill: #4ea83e;
}

.sent-quote-value-chart .quote-declined-value-bar-label.external-bar-label {
  fill: red;
}

.sent-quote-value-chart > *:not(:last-child) {
  margin-bottom: 1rem;
}

.sent-quote-value-chart .categories-key {
  margin-left: 80px;
}

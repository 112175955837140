.create-edit-job-customer-modal .create-edit-customer-control {
  position: relative;
}

.create-edit-job-customer-modal
  .create-edit-customer-control
  .control-type-actions-container {
  margin-bottom: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.create-edit-job-customer-modal
  .basic-create-edit-customer-control
  .form-section:not(:last-child) {
  margin-bottom: 1rem;
}


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.deal-payment-summary {
    width: auto;

    .deal-payment-summary-amount {
        &:not(:last-child) {
            padding-right: 1.5rem;
            border-right: 1px solid rgba($blue-dark, 0.2);
            margin-right: 1.5rem; }

        h4 {
            margin: 0; } } }

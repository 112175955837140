.listed-item-group-control {
  width: 30%;
  min-width: 200px;
  height: 150px;
  background: #fff;
  cursor: pointer;
  transition: box-shadow 150ms ease-out;
  padding: 0;
}
.listed-item-group-control:hover {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  transition: box-shadow 150ms ease-in;
}
.listed-item-group-control .listed-item-group-control-inner {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  justify-content: space-between;
  height: 100%;
  color: #36577d;
}
.listed-item-group-control .listed-item-group-control-inner.add-group {
  justify-content: center;
  align-items: center;
}
.listed-item-group-control
  .listed-item-group-control-inner.add-group
  .icon-wrapper {
  font-size: 18px;
}

.listed-item-group-control .listed-item-group-control-inner .icon-wrapper {
  display: flex;
  justify-content: flex-end;
}
.listed-item-group-control
  .listed-item-group-control-inner
  .listed-item-group-control-name-description {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.listed-item-group-control .group-name {
  border-bottom: 1px solid #ffca2d;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 1.6em;
}
.listed-item-group-control .add-group-name,
.listed-item-group-control .group-name {
  font-size: 1.1em;
}
.listed-item-group-control .add-group-name {
  margin-bottom: 0.5em;
}

.listed-item-group-control .group-description {
  font-size: 0.9em;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

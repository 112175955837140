.deal-payments {
    &, .job-payments-container, .job-finance-information-container {

        > * {
            &:not(:last-child) {
                margin-bottom: 1rem; } } }

    .deal-payment-summary-container {
        padding-bottom: 1rem; }

    .satisfaction-note-signed-block {
        padding-left: 1rem; } }

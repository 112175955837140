@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.create-subscription-guidance-prompts-container {
    display: flex;

    > *:not(:last-child) {
        margin-right: 2rem; }

    .create-subscription-guidance-prompt {
        flex-basis: 50%;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: center;
        padding: 2rem;
        @include center-content();
        flex-direction: column;
        position: relative;
        min-height: 300px;

        .prompt-intro-copy {
            min-height: 5rem;
            @include center-content(); }

        h2 {
            font-size: 20px; }

        .button.button-style-standard {
            min-width: 200px; }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 450px;
            margin: 0 auto;

            > * {
                margin: 0;

                &:not(:last-child) {
                    margin-bottom: 1rem; } } }

        .watch-demo-prompt {
            position: absolute;
            bottom: 1rem; } }

    &.sm-view {
        flex-direction: column;

        > *:not(:last-child) {
            margin-right: 0rem;
            margin-bottom: 2rem; } } }

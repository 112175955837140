.pagination {
  background: #fff2cc;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #c4c4c4;
  border-radius: 0px 0px 5px 5px;
}

.pagination .results {
  margin: 5px;
}

.pagination .pages {
  display: flex;
}

.pagination .pages .page-number {
  cursor: pointer;
  padding: 5px 10px;
  margin: 0px 5px;
}

.pagination .pages .page-number.selected {
  border: 1px solid #fab62d;
  border-radius: 5px;
}

@import '@payaca/styles/variables';

.preview-job-control-wrapper {
    display: flex;
    height: 100%;
    flex-grow: 1;

    > *:not(:last-child) {
        margin-right: 2rem; }

    .lhs {
        flex-grow: 1;

        .title-bar {
            padding-bottom: 1.5rem; }

        .preview-content {
            overflow-y: scroll;
            flex-grow: 1; }

        .preview-content-outer {
            flex-grow: 1;
            position: relative;
            overflow: hidden;
            display: flex; } }


    .rhs {
        flex-shrink: 0;
        overflow: hidden;

        .sidebar-content {
            width: 220px;
            height: 100%;
            overflow-y: scroll; } }

    .lhs, .rhs {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%; }

    &.sm-view {
        flex-direction: column;
        height: auto;

        > *:not(:last-child) {
            margin-right: 0;
            margin-bottom: 2rem; }
        .rhs {
            .sidebar-content {
                width: auto; } } } }

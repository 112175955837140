@import '@payaca/styles/variables.sass';

.login-form-container {
    > .feedback-message {
        margin-top: 0.5rem; }

    form {
        > *:not(:last-child) {
            margin-bottom: 1rem; }

        button {
            width: 100%; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.content-carousel {

    .carousel {
        padding-bottom: 2rem; }

    .control-dots {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        > *:not(:last-child) {
            margin-right: 1.5rem; } }

    .carousel-indicator {
        display: inline;
        background-color: $blue-dark;
        opacity: 0.5;
        height: 5px;
        flex-grow: 1;
        max-width: 4rem;
        border-radius: 2.5px;
        cursor: pointer;


        &.selected {
            opacity: 1; } } }

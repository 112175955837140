.setup-account-company-form {
  .validated-form {
    .validated-field-wrapper {
      margin-bottom: 10px;
      .field-label {
        padding-bottom: 8px; } }

    .button-container {
      margin-top: 1.5rem;
      button.button-style-outsize {
        width: 100%; } } } }

.sent-quotes-chart .quote-pending {
  fill: #fabb00;
  background-color: #fabb00;
}

.sent-quotes-chart .quote-accepted {
  background-color: #4ea83e;
  fill: #4ea83e;
}

.sent-quotes-chart .quote-declined {
  background-color: red;
  fill: red;
}

.sent-quotes-chart .internal-bar-label {
  fill: white;
}

.sent-quotes-chart .quote-pending-bar-label.internal-bar-label,
.sent-quotes-chart .quote-pending-bar-label.external-bar-label {
  fill: #263e59;
}

.sent-quotes-chart .quote-accepted-bar-label.external-bar-label {
  fill: #4ea83e;
}

.sent-quotes-chart .quote-declined-bar-label.external-bar-label {
  fill: red;
}

.sent-quotes-chart > *:not(:last-child) {
  margin-bottom: 1rem;
}

.sent-quotes-chart .categories-key {
  margin-left: 80px;
}

@import './variables.sass';

@mixin box-shadow() {
  box-shadow: 0 4px 10px 0 rgba($blue-dark, 0.15); }

@mixin border-radius-lg() {
  border-radius: 15px; }

@mixin border-radius() {
  border-radius: 10px; }

@mixin border-radius-sm() {
  border-radius: 5px; }

@mixin center-content() {
  display: flex;
  align-items: center;
  justify-content: center; }

@mixin border-top($borderWidth: 1px) {
  border-top: $borderWidth solid rgba($col-secondary-dark, 0.2); }

@mixin border-bottom($borderWidth: 1px) {
  border-bottom: $borderWidth solid rgba($col-secondary-dark, 0.2); }

@mixin container-background($padding: 1rem) {
  background: $blue-lighter;
  padding: $padding;
  @include border-radius(); }


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';


.manage-subscription-page {
  .header-content {
    .title-bar {
      padding-bottom: 0;
      padding-top: 0;
      flex-direction: column;

      .title-bar-inner {
        padding-bottom: 1rem;
        padding-top: 1rem;
        height: 100%;
        border-bottom: 1px solid $blue-light;
        width: 100%;

        h1 {
          flex-grow: 1;
          text-align: center; }

        .phone-number-container {
          justify-content: flex-end; }

        > * {
          flex-basis: 0;
          flex-grow: 1;
          flex-shrink: 1; } } } }

  .body-content {
    padding: 2rem 2rem 2rem 4rem; }

  .contact-us-callout-container {
    width: 100%;
    margin-bottom: 2rem;

    .contact-us-callout {
      margin: 0 auto;
      max-width: 100%;
      padding: 1rem 2rem; } } }

.login-control {
    > *:not(:last-child) {
        margin-bottom: 2rem; }

    .login-with-email-prompt {
        hr {
            flex-grow: 1; } }

    .forgot-password-prompt {
        text-align: center;
        margin-top: 1rem; }

    .login-form-container + .feedback-message {
        margin-top: 0.5rem; }

    > *:not(.login-with-email-prompt) {
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto; }

    .social-login-buttons-container {

        > *:not(:last-child) {
            margin-bottom: 1rem; } } }

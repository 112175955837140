
@import '@payaca/styles/mixins.sass';

.verify-user-page {
  .body-content {

    .loader-container {
      @include center-content(); }

    > div {
      width: 440px;
      max-width: 100%; }

    .title-introduction-container {
      text-align: center; }

    > *:not(:last-child) {
      margin-bottom: 2rem; }

    .resend-verification-email-control,
    button {
      width: 100%;
      max-width: 300px;
      margin: 0 auto; } } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.navigation-sidebar {
    height: 100%;
    color: $white;



    .navigation-menu-items-container {
        hr {
            margin: 0rem 1.2rem;
            border-color: rgba($white, 0.2); } }

    .navigation-menu-item {
        padding: 1.2rem 24px;
        width: 255px;
        min-width: 255px;

        .icon-container {
            background-color: rgba($white, 0); }

        &:not(.disabled) {

            &:hover {
                background-color: rgba($white, 0.1); } }

        &.selected {
            background-color: rgba($white, 0.1); } }

    .current-account-container {
        border-bottom: 1px solid $white;

        .navigation-menu-item {
            cursor: default;

            &:hover {
                background-color: transparent; }


            .icon-container {
                overflow: hidden;

                img {
                    width: 36px;
                    height: 36px;
                    object-fit: cover; } }

            .title-container {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; } } }

        &.clickable {
            .navigation-menu-item {
                cursor: pointer;

                &:hover {
                    background-color: rgba($white, 0.1); } } } }



    .collapsible-sidebar.collapsed {
        .navigation-menu-item {

            .title-container {
                opacity: 0; } } } }


@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.deal-payment-item {
    background-color: $blue-lighter;
    overflow: hidden;
    @include border-radius();
    padding: 0.5rem 1rem;
    padding-left: 0;
    border-left: 1rem solid;

    .deal-payment-amount {
        min-width: 10rem;
        padding: 1em;
        text-align: center;
        @include center-content();
        border-right: 1px solid rgba($blue-dark, 0.2);

        h3 {
            font-size: 1.2rem; } }

    .deal-payment-data {
        flex-grow: 1;

        h4 {
            margin-top: 0;
            margin-bottom: 0; }

        .payment-label {
            margin-bottom: 0.5rem; } }

    .deal-payment-action {
        @include center-content(); }

    &.complete {
        border-color: $green;
        .badge {
            background-color: $green; } }

    &.pending {
        border-color: $yellow-dark;
        .badge {
            background-color: $yellow-dark; } }

    &.failed {
        border-color: $red;
        .badge {
            background-color: $red;
            color: $white; } } }



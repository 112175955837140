@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.pagination-control {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  width: 100%;

  > * {
    flex-basis: calc(100% / 3);
    flex-grow: 1; }

  > *:not(:last-child) {
    margin-right: 3rem; }

  .total-item-count {
    font-weight: 600; }

  .page-selection-control {
    display: flex;
    align-items: center;
    color: #36577d;
    justify-content: center;

    > * {
      padding: 0.5rem;

      &:not(:last-child) {
          margin-right: 1em; } }




    .fa-chevron-left, .fa-chevron-right {
      font-size: 0.8em; }

    span {
      &.active {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        color: #263e59; }

      &:not(.ellipsis) {
        cursor: pointer; } } }

  .page-size-control {
    display: flex;
    align-items: center;
    justify-content: right;

    span {
      white-space: pre; }

    > *:not(:last-child) {
      margin-right: 1em; }

    .dropdown-field .input-wrapper {
      padding: 0.2em 0.4em;
      background-color: $blue-lighter;
      box-shadow: none;

      .MuiInputBase-root {
        font-size: 0.9em; }

      .MuiSelect-icon {
        transform: translate(6px, 0); }

      .MuiSelect-select.MuiSelect-select {
        padding-right: 18px; } } }

  .arrow-control {
    @include box-shadow();
    @include center-content();
    height: 30px;
    width: 30px;
    border-radius: 50%; } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-line-item-attachments-control {
  .job-attachments-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .job-attachment {
      @include box-shadow();
      background-color: $white; } }

  .loader-container {
    height: 159px;
    display: flex;
    align-items: center;
    justify-content: center; } }

.subsidised-finance-information-overview-page h4.application-status {
  font-size: 1.2rem;
}

.subsidised-finance-information-overview-page
  .subsidised-finance-information-overview-page-content {
  padding: 2rem;
}

.subsidised-finance-information-overview-page-content > .content-panel {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.subsidised-finance-information-overview-page-content
  .button-container
  .button {
  margin: 0 auto;
  min-width: 200px;
  display: block;
}

.subsidised-finance-information-overview-page-content
  > .content-panel
  > *:not(:last-child) {
  margin-bottom: 2rem;
}

.subsidised-finance-information-overview-page-content .button-container {
  padding: 1rem 0;
}

.subsidised-finance-information-overview-page-content
  .application-status-container {
  width: 100%;
  background-color: #f1f6fd;
  border: 1px solid #7eb0f1;
  border-radius: 0.35rem;
  text-align: center;
  padding: 1rem;
}

.subsidised-finance-information-overview-page-content a {
  text-decoration: underline;
}

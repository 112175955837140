.image-upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.image-upload .image-container {
  border: 1px solid #000;
  height: 300px;
  width: 400px;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.image-upload .image-container img {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  margin: auto;
}

.image-upload input {
  display: none;
}

.image-upload .error {
  color: red;
  text-align: center;
  padding-top: 5px;
}

.image-upload .button {
  width: 250px;
}

.image-upload > *:not(:last-child) {
  margin-bottom: 1rem;
}

.subscription-control-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 1rem;
  .badge {
    position: absolute;
    left: 0;
    top: 0;
    text-transform: none; }
  h3 {
    margin: 1rem 0 0.5rem; }
  p {
    margin: 0 0 1.5rem; } }
@media (max-width: 800px) {
  .subscription-control-header {
    .badge {
      position: relative; } } }

.offline-banner {
  display: flex;
  background: #c4c4c4;
  justify-content: center;
  padding: 0.5rem;
  align-items: center;
  text-align: center;
  svg {
    margin-right: 1rem;
    height: 30px; } }

.listed-deals-page {
    .listed-deals-title-bar {
        &.sm-view {
            display: block;

            .listed-deals-filters .filters-wrapper {
                flex-wrap: wrap;
                margin-top: 1em; } }

        .listed-deals-filters {
            align-items: center; } }

    .listed-deals-table-container {
        height: 100%;

        .listed-deals-table {
            table {
                padding: 0 2rem 0 4rem; }

            .pagination-control {
                padding: 1rem 2rem 2rem 4rem; } } } }

.setup-user-form-container {
    form {
        > *:not(:last-child) {
            margin-bottom: 1rem; }

        button {
            width: 100%;

            &+ .feedback-level-error {
                margin-top: 0.5rem; } } } }



@import '@payaca/styles/variables';
@import '@payaca/styles/mixins';

.selected-product-overview {
  background-color: $blue-light;
  padding: 1.5rem;
  @include border-radius();

  p, h3 {
    margin: 0; }

  .product-name {
    align-items: baseline;
    display: flex; }

  .product-name > h3 {
    margin-right: 0.5em; }

  .recurring-interval {
    font-size: 0.8em; }

  > *:not(:last-child) {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem; }

  .user-seats .value {
    min-width: 75px;
    text-align: right; }

  .label-value-pair {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 1rem; }

    .label {
      font-weight: normal;
      flex-grow: 1; }

    .value {
      font-weight: bold; } }

  .additional-user-seats-control {
    .button {
      padding: 0.5rem 0.6rem; }

    > *:not(:last-child) {
      margin-right: 0.5rem; } }

  .tooltip-wrapper svg {
    padding: 0; }

  .quantity-control-wrapper {
    display: flex;
    > span:first-child {
      margin-right: 0.5rem; } }

  .total {
    border-top: 1px solid rgba($blue-darker, 0.2);
    padding: 1.5rem 0; } }

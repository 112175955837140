.director-form-subsection .flex-container > .titled-content-panel {
  width: 100%;
}

.director-form-subsection .content > *:not(:last-child) {
  margin-bottom: 1rem;
}

.director-form-subsection .fa-times {
  cursor: pointer;
}


@import 'variables.sass';
@import 'mixins.sass';

$border-width: 1px;

table.styled-table {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    width: calc(100% + 0.5px);
    border-collapse: separate;
    border-spacing: 0 10px;


    tbody, tfoot, thead {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background-color: transparent;
        box-shadow: none;
        cursor: default;
        font-weight: normal; }

    th, td {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background-color: transparent;
        box-shadow: none;
        cursor: default;
        font-weight: normal; }


    td {
        padding: 0.15em 0.5em;
        text-align: left;
        vertical-align: middle;
        transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;


        &.row-selection-table-cell {
            padding: 0;
            text-align: center;
            vertical-align: middle; } }



    td.align-right, th.align-right {
        text-align: right; }

    td.align-center, th.align-center {
        text-align: center; }

    tr {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0; }

    tbody {

        tr {
            td:not(.tr-exclude) {
                background-color: $blue-lighter;
                border-color: transparent;
                border-top: $border-width solid transparent;
                border-bottom: $border-width solid transparent;

                &.tr-start,
                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border-left: $border-width solid transparent; }

                &.tr-end,
                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border-right: $border-width solid transparent; } }

            &.selected {
                td:not(.tr-exclude), {
                    border-color: $yellow; } }

            &.clickable {
                cursor: pointer;

                td {
                   cursor: pointer; }

                &:hover {
                    td:not(.tr-exclude) {
                        background-color: $blue-light; } } }

            &.disabled {
                cursor: default;

                td {
                    opacity: 0.5; }

                &:hover {
                    td:not(.tr-exclude) {
                        background-color: $blue-lighter;
                        border-color: transparent; } } } }



        &:not(:last-child) {
            td {
                margin-bottom: 10px; } } }


    thead {
        td, th {
            background-color: white;
            text-align: left;
            font-weight: 600;
            padding: 0 0.5em 0.15em 0.5em; } }

    tfoot {


        td {
            background-color: $white;
            padding: 0.15em 0.5em 0 0.5em;

            .pagination-control {
                margin-left: -0.5em;
                margin-right: -0.5em;
                width: calc(100% + 1em); } } } }

.scrollable-table-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
  margin-bottom: 10px;

  table.styled-table {

    margin-top: -10px;
    margin-bottom: -10px;

    thead {
      position: sticky;
      top: 0;
      z-index: 100; }

    tfoot {
      position: sticky;
      bottom: 0;
      z-index: 100; } } }

.stepped-progress-tracker .stepped-progress-tracker-inner {
  display: flex;
  width: 100%;
}

.stepped-progress-tracker .step {
  text-align: center;
  flex-shrink: 1;
  position: relative;
  width: 0;
}

.stepped-progress-tracker .step .step-name {
  line-height: 1em;
  display: block;
}

.stepped-progress-tracker .step .step-indicator {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 0.5rem;
}

.stepped-progress-tracker .step .step-indicator-label {
  line-height: 2rem;
  font-size: 2rem;
  margin-top: 0.4rem;
}

.stepped-progress-tracker .step .step-indicator.clickable {
  cursor: pointer;
}

.stepped-progress-tracker .step-connector {
  height: 3px;
  flex-grow: 1;
}

.step .step-indicator,
.step-connector {
  background-color: #cac6bf;
}

.stepped-progress-tracker .step.completed-step .step-indicator,
.stepped-progress-tracker .step-connector.completed-step-connector {
  background-color: #8bc34a;
}

.stepped-progress-tracker .step.current-step .step-indicator,
.stepped-progress-tracker .step-connector.current-step-connector {
  background-color: #5ba7ff;
}

.stepped-progress-tracker .step .step-name {
  color: #888172;
}

.stepped-progress-tracker .step.completed-step .step-name,
.stepped-progress-tracker .step.current-step .step-name {
  color: #000;
}

.stepped-progress-tracker.vertical-view .stepped-progress-tracker-inner {
  flex-direction: column;
}

.stepped-progress-tracker.vertical-view {
  padding: 0 !important;
}

.stepped-progress-tracker.vertical-view .step {
  width: 100%;
  flex: 0 0 auto;
}

.stepped-progress-tracker.vertical-view .step .step-inner {
  width: 100% !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.stepped-progress-tracker.vertical-view .step .step-inner .step-name {
  margin: 0 !important;
  text-align: left;
}

.stepped-progress-tracker.vertical-view .step .step-inner .step-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0;
  flex-shrink: 0;
}

.stepped-progress-tracker.vertical-view .step-connector {
  height: calc(40px + 0.5rem);
  flex-grow: 1;
  width: 3px;
  margin: -20px 0 -20px 18px !important;
}

.advertising-material-content-panel .button {
  margin: 0;
}

.advertising-material-content-panel h4 {
  margin: 0;
}

.advertising-material-content-panel > *:not(:last-child) {
  margin-bottom: 1rem;
}

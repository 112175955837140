.job-totals-and-value-modifiers-control hr {
  margin: 0.75rem 0;
}

.job-totals-and-value-modifiers-control .detail-container {
  display: flex;
  justify-content: space-between;
}

.job-totals-and-value-modifiers-control .detail-icon {
  font-size: 16px;
  margin: 0px 10px;
}

.job-totals-and-value-modifiers-control .editable-value-title-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.job-totals-and-value-modifiers-control .editable-value-title {
  text-decoration: underline;
}

.job-totals-and-value-modifiers-control .editable-value-title.disabled {
  text-decoration: none;
}

.job-totals-and-value-modifiers-control .tooltip-icon {
  font-size: 0.8em;
  margin: 0 0 0 0.5em;
  padding: 0px;
}

.job-totals-and-value-modifiers-control .separator {
  height: 1px;
  background: #c4c4c4;
  margin: 10px 0px;
}

.job-totals-and-value-modifiers-control .disabled {
  color: #575756;
}

@import '@payaca/styles/variables.sass';

.social-login-button-container {
  width: 100%;

  .button {
    width: 100%;
    font-weight: 400;

    .button-content {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px; }

      .fa-facebook {
        font-size: 20px;
        color: $blue-facebook; }

      span {
        margin-left: 0.5rem; } } } }


#appleid-signin {
  cursor: pointer;
  margin-bottom: 8px;

  &:focus {
    outline: none; }

  svg {
    height: 40px; } }

@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.subscription-usage-indicator-inner {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: -2rem;

  p {
    margin: 0; }

  h3 {
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.2rem; }

  .available-used-container {
    display: flex; }

  > * {
    max-width: 250px;
    margin-bottom: 2rem; }

  .zero-percent-container {
    display: flex;
    align-items: center; }

  .figure {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1em; }

  .available-used-container {
    .available, .used {
      text-align: center;
      min-width: 100px;
      max-width: 100px; }

    p {
      font-size: 0.9rem;
      line-height: 1.2em;
      margin-top: 0.5rem; }

    .divider {
      margin: 0 1rem;
      border-left: 1px solid #c4c4c4; } }

  .warning {
    color: red; }

  .update-additional-user-seats-trigger-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center; } }


.subscription-usage-indicator.loader-container {
  @include center-content(); }
